import { create } from 'app/modules/inview';
import { formUrlWithParams, prependLanguagePrefix, rafPromise, noop } from 'app/shared/utils';

/**
 * FeedModules defines functionality for loading curated breaker modules
 * spread through a listing feed.
 */
export default class FeedModules {
  constructor() {
    // feed module ajax endpoint
    this.ajaxUrl = prependLanguagePrefix('/ajax/feedmodules/');

    this.moduleSelector = '.deferred-feed-module';

    this.observer = create([this.handleInView.bind(this), noop], { rootMargin: '200%' });

    // set up modules
    this.observeModules();
  }

  observeModules() {
    // set the module collection excluding any loading or loaded modules
    document.querySelectorAll(this.moduleSelector).forEach(el => this.observer.observe(el));
  }

  handleInView({ target }, observer) {
    observer.unobserve(target);
    const url = formUrlWithParams(this.ajaxUrl, target.dataset);

    Promise.resolve()
      .then(() => fetch(url))
      .then(response => response.text())
      .then(html => rafPromise(() => FeedModules.handleModuleLoaded(target, html)));
  }

  static handleModuleLoaded(module, response) {
    // replace the module with the returned html
    module.outerHTML = response;
  }
}
