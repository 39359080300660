export default class HashedModals {
  constructor() {
    this.hashedModalsList = ['#searchoverlay', '#sidepanel', '#location-choice'];
    this.hashedModals = this.hashedModalsList.map(hashedModalRef => ({
      hashId: hashedModalRef,
      elm: document.querySelector(hashedModalRef),
      display: undefined,
    }));

    // Setup lifecycle events
    this.updateHashedModalsHandler = () => this.updateHashedModals();
    window.addEventListener('hashchange', this.updateHashedModalsHandler);
    window.addEventListener('load', this.updateHashedModalsHandler);
  }

  /**
   * Update hashed modals
   */
  updateHashedModals() {
    this.hashedModals.forEach((hashedModal, i) => {
      if (hashedModal.elm instanceof HTMLElement) {
        // Cache initial element's display property (to support "flex" value)
        if (hashedModal.display === undefined) {
          this.hashedModals[i].display = getComputedStyle(hashedModal.elm).display;
        }

        if (window.location.hash === hashedModal.hashId) {
          hashedModal.elm.setAttribute('aria-hidden', 'false');
          // immidiate call here needed to activate element before FocusTrap take it
          hashedModal.elm.style.display = hashedModal.display;
        } else {
          hashedModal.elm.setAttribute('aria-hidden', 'true');
          // delayed call here needed to keep css transition visible
          setTimeout(() => {
            hashedModal.elm.style.display = 'none';
          }, 300);
        }
      }
    });
  }
}
