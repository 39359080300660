import DataLayer from 'app/modules/datalayer';
import UserEvents from 'app/shared/userevents';

/**
 * Share tracks and triggers user events, for the purpose of social and analytics tracking.
 */
export default Backbone.View.extend({
  el: 'body',

  events: {
    'click .body-btn-link': 'trackBodyButtonLink',
    'click .clickable-image-button': 'trackClickableImage',
    'click .clickable-image-link': 'trackClickableImage',
    'click .grid-wrapper-link': 'trackWrapperLink',
    'click .social-button': 'trackSocialButtons',
  },

  /**
   * Triggers an user event when a social button is clicked
   *
   * @param  {jQuery} event Click event
   *
   */
  trackSocialButtons: function trackSocialButtons(event) {
    let $currentTarget = $(event.currentTarget);
    let $buttonGroup = $currentTarget.closest('.social-button-group');
    // Get share location and social network
    let values = [$buttonGroup.data('shareLocation'), $currentTarget.data('network')];

    // Trigger user event
    // Example: social-btns-primary-facebook
    UserEvents.trigger(values.join('-'));
  },

  /**
   * Triggers an user event when a button with `.body-btn-link` class is clicked
   *
   * @param  {jQuery} event Click event
   * @param  {jQuery} link OPTIONAL body button link object passed directly from handler
   */
  trackBodyButtonLink: function trackBodyButtonLink(event, $link) {
    let $currentTarget = event ? $(event.currentTarget) : $link;
    let targetName = $currentTarget.html().trim();
    let targetUrl = $currentTarget.attr('data-href') || $currentTarget.attr('href') || '';
    let targetClass = $currentTarget.attr('class');
    let values = {
      name: targetName,
      url: targetUrl,
      class: targetClass,
      'data-tracking-label': targetName,
      'destination-url': targetUrl,
      context: {
        contentId: DataLayer.getValue('content.id'),
        articleId: DataLayer.getValue('content.universalId'),
        site: DataLayer.getValue('site.name'),
        locale: DataLayer.getValue('locale.language'),
        template: DataLayer.getValue('template'),
      },
    };

    UserEvents.trigger('body-btn-link-event', values);

    // don't bubble click up to wrapper link
    if (event) {
      event.stopPropagation();
    }
  },

  /**
   * Triggers an user event when a clickable image or the button inside is clicked
   *
   * @param  {jQuery} event Click event
   */
  trackClickableImage: function trackClickableImage(event) {
    let $currentTarget = $(event.currentTarget);
    let $clickedImage = $currentTarget.closest('.clickable-image-link');
    let imagesize = $clickedImage.data('size') || '';
    let imageId = $clickedImage.data('id') || '';
    let eventData = $.extend({}, DataLayer.getValue('content.selectedMedia.' + imageId));

    eventData.action = 'click';
    eventData.element = 'image';
    eventData.imageSize = imagesize;

    // do not fire an event for product links, they are handled separately
    if ($currentTarget.hasClass('product-links')) {
      return;
    }

    // when it is the button inside the clickable image got clicked, stop event bubbling up
    if ($currentTarget.hasClass('clickable-image-button')) {
      event.stopPropagation();
      eventData.element = 'cta';
    }

    if ($currentTarget.hasClass('clickable-image-embed-item')) {
      event.stopPropagation();
      eventData.contentMediaId = imageId;
      eventData.destUrl = $clickedImage.data('desturl');
      eventData.order = $clickedImage.data('order');
      eventData.role = $clickedImage.data('role');
    }

    if ($currentTarget.hasClass('clickable-image-product-link')) {
      eventData.brand = $currentTarget.data('customBrand') || $currentTarget.data('brand');
      eventData.category = $currentTarget.data('category');
      eventData.price = $currentTarget.data('price');
      eventData.productId = $currentTarget.data('productId');
      eventData.sem3Id = $currentTarget.data('sem3Id');
    }

    UserEvents.trigger('clickable_images.event.click', eventData);
  },

  // TODO: create a new method that handles trackClickableProductImage

  /**
   * Handles clicks on entire elements wrapped in body/product button links.
   * Searches for interior buttons and calls the appropriate tracker function.
   *
   * @param  {jQuery} event Click event
   */
  trackWrapperLink: function trackWrapperLink(event) {
    let $currentTarget = $(event.currentTarget);
    let $bodyButton = $currentTarget.parents('.grid-item').find('.body-btn-link');

    if ($bodyButton.length) {
      this.trackBodyButtonLink(null, $bodyButton);
    }
  },
});
