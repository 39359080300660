import newFocusTrap from 'focus-trap';

let trap = null;

/**
 * FocusTrapping is the main function that interact with focus-trap library,
 * taking HTML element (el) that need to be trapped. Also it takes
 * returnFocus as optional html element to return the focus to in case of deactivation.
 */
const FocusTrapping = (el, returnFocus = '.nav-sidepanel-button.show-menu') => {
  const element = document.querySelector(el);
  const allowOutsideClick = () => true;
  const clickOutsideDeactivates = () => true;
  return newFocusTrap(el, {
    onDeactivate: () => {
      element.classList.remove('focus-trap');
    },
    onActivate: () => {
      element.classList.add('focus-trap');
    },
    allowOutsideClick: allowOutsideClick(),
    clickOutsideDeactivates: clickOutsideDeactivates(),
    setReturnFocus: returnFocus,
  });
};

/**
 * Activate focus trapping based on Location.hash value
 * recive array hashes to watch for
 */
const activateFocusTrap = (hashesOnWatch = []) => {
  const windowHash = window.location.hash;
  if (trap instanceof Object) {
    trap.deactivate();
  }
  if (hashesOnWatch.includes(windowHash)) {
    trap = FocusTrapping(windowHash);
    trap.activate();
  }
};

/**
 * Setup the Event Listner for Esc key button
 * the function clear the location.hash or remove class from html element
 * Example of modals: [
 *  {type: 'hashes', values: ['#sidepanel', '#searchoverlay']},
 *  {type: 'element', value: '.show-account-dropdown'}
 * ]
 * @param {array} modals  List of modals
 * @param {string} modals[0].type 'hashes' or 'element'
 * @param {string|array} modals[0].value identifier
 */
const closeModals = (modals = []) => {
  document.onkeydown = (e) => {
    if (e.keyCode === 27) {
      modals.forEach((modal) => {
        if (modal.type === 'hashes') {
          const windowHash = window.location.hash;
          if (modal.values.includes(windowHash)) {
            window.location.hash = '';
            e.preventDefault();
          }
        } else if (modal.type === 'element') {
          const elem = document.querySelector(modal.value) || null;
          if (elem) {
            // skip #/. of the html element
            elem.classList.remove(modal.value.substring(1));
          }
        }
      });
    }
  };
};

/**
 * Toggle focus trapping based on the toggleValue,
 * it takes html element and value for the toggle, if
 * true then activate otherwise deactivate.
 */
const toggleFocus = (elem, toggleValue = true) => {
  if (trap instanceof Object) {
    trap.deactivate();
  }
  if (toggleValue) {
    trap = FocusTrapping(elem);
    trap.activate();
  } else {
    trap.deactivate();
  }
};

export { FocusTrapping, closeModals, activateFocusTrap, toggleFocus };
