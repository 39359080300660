import Logger from 'app/shared/logger';
import VideoPlayer from 'app/modules/videoplayer';

/**
 * MosPlayer defines the functionality for mos video embeds
 * */
export default VideoPlayer.extend({
  playerType: 'mos',

  logger: new Logger('mosplayer'),

  supportedPlayerStates: {
    play: ['playing', 'play', 'firstFrame', 'adPlay', 'buffer'],
    pause: ['pause', 'paused'],
    end: ['complete'],
  },

  /**
   * Initialize the Youtube Player
   *
   */
  initialize: function initialize() {
    // Return early if there are no matching elements
    if (!this.$el.length) {
      return;
    }

    this.setVariables();
    this.setPlayer();
  },

  /**
   * Sets variables
   */
  setVariables: function setVariables() {
    this.player = this.$el.get(0);

    this.autoplayCache = false;

    this.playerEvents = {
      onStateChange: this.onPlayerStateChange.bind(this),
    };
  },

  // Begin gettters/setters

  setPlayer: function setPlayer() {
    if (this.isReady() && this.player.hasOverrides) {
      return this.player;
    }
    // Attempt to override options to the player
    try {
      this.player.override(this.playerEvents);
      this.player.hasOverrides = true;
      this.initializePlayer();
    } catch (err) {
      this.logger.warn('player failed to override options', {
        player: this.player,
      });
    }
    return this.player;
  },

  /**
   * Gets the current state of the player
   *
   * @return {string} String value of the current state
   */
  getState: function getState() {
    return this.player.getCurrentState();
  },

  // End getters/setters

  // Begin utilities

  /**
   * Checks if player is ready
   *
   * @return {bool} True, if player is ready
   */
  isReady: function isReady() {
    return this.player.isReady;
  },

  /**
   * Proxies to player method
   *
   * @return {bool} True, if player is set to autoplay
   */
  isAutoplay: function isAutoplay() {
    return this.player.getAutoplay();
  },

  // End utilities

  // Begin event handlers

  /**
   * Proxies to handleStateChange passing the state value
   *
   * @param {html} player Player element
   * @param {string} state  State the player has changed to
   */
  onPlayerStateChange: function onPlayerStateChange(player, state) {
    this.handleStateChange(state);
  },

  // End event handlers

  // Begin player action handlers

  /**
   * Prevents a player from pausing after going out of view
   */
  pausePlayer: function pausePlayer() {},

  // End player action handlers
});
