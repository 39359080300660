/**
 * Debounce module handles functionality related to debouncing scroll,
 * resize, and other events that are triggered repeatedly in very quick
 * succession.
 *
 *
 * Usage:
 *
 * // scroll handlers will get lastScrollY
 * function handleScroll( lastScrollY ) {
 *     // lastScrollY === window.pageYOffset
 * }
 *
 * // resize handlers will get lastWidth and lastHeight
 * function handleResize( lastWidth, lastHeight ) {
 *     // lastWidth === window.innerWidth
 *     // lastHeight === window.innerHeight
 * }
 *
 * When binding a handler, a third key argument can be passed in. If
 * present, the handler will be stored in a named queue. This is useful
 * for unbinding a suite of handlers with one statement. Ex:
 *
 * // bind a handler
 * Debounce.on( "scroll", handleScroll, "myKey" );
 * Debounce.on( "resize", handleResize, "myKey" );
 *
 * // unbind a handler
 * Debounce.off( "scroll", "myKey" );
 * Debounce.off( "resize", "myKey" );
 *
 * Alternatively, you can bind a handler without specifying a key. If
 * no key is specified, a key will be generated and attached to the
 * handler. In order to unbind, you will need to pass in the
 * handler function instead of the key. Ex:
 *
 * // Bind "anonymously"
 * Debounce.on( "scroll", handleScroll );
 *
 * // Unbind "anonymously"
 * Debounce.off( "scroll", handleScroll );
 *
 *
 * Advanced Usage:
 *
 * With great power comes great responsibility. Don't remove the scroll
 * or resize events. It would probably be bad.
 *
 * // Add your own events
 * Debounce.addEvent( "touchmove", function( event ) {
 *     var touches = event.touches;
 *
 *     // process the queue and pass what you need along
 *     Debounce.processQueue( "touchmove", [ touches ] );
 * } );
 *
 * // Now you can add handlers for your event
 * Debounce.on( "touchmove", function( touches ) {
 *     // touches === event.touches
 * } );
 *
 * // You can remove the custom event you added
 * // It will unbind everything you bound.
 * Debounce.removeEvent( "touchmove" );
 */
const Debounce = {
  // Common event namespace to detach events properly
  namespace: '.debounce',

  // Simple constructor to help keep track
  // of regitered events' states
  EventState: function EventState(handler) {
    this.ticking = false;
    this.listening = false;
    this.queue = {};
    this.handler = handler;
  },

  /**
   * The events cache will contain EventStates that help us track queues, states,
   * and handlers for multiple events using an identical interface.
   *
   * See EventState contructor above.
   */
  events: {},

  // Adds an event state
  addEvent: function addEvent(eventName, handler) {
    this.events[eventName] = new this.EventState(handler);
  },

  // Removes an event state
  removeEvent: function removeEvent(eventName) {
    // cache the event
    let state = this.events[eventName];

    // if the state doesn't exist, return false
    if (!state) {
      return false;
    }

    // loop over the queue's keys and make sure all
    // of the handlers are unbound
    Object.keys(state.queue).forEach((key) => {
      this.off(eventName, key);
    });

    // delete the event from the events cache
    delete this.events[eventName];

    // return true for success
    return true;
  },

  // Generates a "unique" function id
  generateFunctionId: function generateFunctionId() {
    return Date.now().toString() + Math.random();
  },

  // binds a callback to a debounced event name
  // key is optional and can be used if events need to be unbound with Debounce.off
  on: function on(eventName, callback, key) {
    let state = this.events[eventName];
    // placeholder to cache the queue
    let queue;

    // return false if we didn't find a valid state
    if (!state) {
      return false;
    }

    // if a key wasn't provided, generate a function id and
    // attach it to the callback
    if (key === undefined) {
      // eslint-disable-next-line no-param-reassign
      key = this.generateFunctionId();

      // attach the id to the callback using our namespace
      // eslint-disable-next-line no-param-reassign
      callback[this.namespace] = key;
    }

    // cache the queue
    queue = state.queue;

    // if this queue doesn't have anything registered under this key,
    // create a new array for the key
    if (!Object.prototype.hasOwnProperty.call(queue, key)) {
      queue[key] = [];
    }

    // add the callback to the queue
    queue[key].push(callback);

    // if we're not already listening to this event, bind to it
    // and set the listening flag to true
    if (!state.listening) {
      // bind to the event
      window.addEventListener(eventName, state.handler);

      // set the listening flag
      state.listening = true;
    }

    // return true for success
    return true;
  },

  // checks if key is a function and returns correct namespace
  getKey: function getKey(key) {
    // if the key is a function, look for a function id
    // otherwise return the key that was passed in
    return typeof key === 'function' ? key[this.namespace] : key;
  },

  // checks if event has been bound
  has: function has(eventName, key) {
    // cache the state
    const state = this.events[eventName];

    // return false if we didn't find a valid state
    if (!state) {
      return false;
    }

    return !!state.queue[this.getKey(key)];
  },

  // unbinds a callback from a debounced event name
  off: function off(eventName, key) {
    // If key does not exist for eventName, exit
    if (!this.has(eventName, key)) {
      return false;
    }

    // cache the state and queue
    const state = this.events[eventName];
    const queue = state.queue;

    // delete this key from the event's queue
    delete queue[this.getKey(key)];

    // if there are no more listeners, unbind from this event
    if (Object.keys(queue).length < 1) {
      window.removeEventListener(eventName, state.handler);

      // flip the listening flag to false
      state.listening = false;
    }

    // return true for success
    return true;
  },

  // Checks to see if the event queue is procession
  // If not, calls requestAnimationFrame
  processQueue: function processQueue(eventName, args) {
    // cache the state
    let state = this.events[eventName];

    // return false if state is invalid or ticking
    if (!state || state.ticking) {
      return false;
    }

    // set the ticking flag to true
    state.ticking = true;

    // call requestAnimationFrame
    return this.requestAnimationFrame(this.handleAnimationFrame.bind(this, state, args));
  },

  // Use requestAnimationFrame or polyfill with a setTimeout
  // that runs at ~60 ticks per second
  requestAnimationFrame: function requestAnimationFrame(callback) {
    let raf = this.getRequestAnimationFrame();

    if (raf) {
      raf(callback);
    } else {
      window.setTimeout(callback, 1000 / 60);
    }
  },

  // returns window.requestAnimationFrame
  getRequestAnimationFrame: function getRequestAnimationFrame() {
    return window.requestAnimationFrame;
  },

  // Processes an event queue
  handleAnimationFrame: function handleAnimationFrame(state, args) {
    // cache the queue
    let queue = state.queue;

    // loop through the queue
    Object.keys(queue).forEach(function iterate(key) {
      // loop over the callbacks
      queue[key].forEach(callback => callback.apply(callback, args));
    });

    // flip the ticking flag to false once we're done
    // eslint-disable-next-line no-param-reassign
    state.ticking = false;
  },

  // handles the scroll event
  handleScroll: function handleScroll() {
    // get the scroll y
    let lastScrollY = window.pageYOffset;

    // process the scroll queue
    this.processQueue('scroll', [lastScrollY]);
  },

  // handles the resize event
  handleResize: function handleResize() {
    // get the window dimensions
    let lastWidth = window.innerWidth;
    let lastHeight = window.innerHeight;

    // process the resize queue
    this.processQueue('resize', [lastWidth, lastHeight]);
  },
};

// Register scroll event
Debounce.addEvent('scroll', Debounce.handleScroll.bind(Debounce));

// Register resize event
Debounce.addEvent('resize', Debounce.handleResize.bind(Debounce));

export default Debounce;
