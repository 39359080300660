import UserEvents from 'app/shared/userevents';

/**
 * Embedded galleries using the Siema carousel.
 */
export default Backbone.View.extend({
  /**
   * Number of thumbnails to show at a time.
   */
  thumbnailCount: 7,

  /**
   * Count of slides to preload the src and srcset attributes
   * of either side of the current slide.
   */
  preloadSlideWindow: 2,

  /**
   * Movement in pixels that constitutes a drag that should trigger the next/previous slide.
   */
  draggingThreshold: 20,

  /**
   * Event listeners for Backbone.
   */
  events: {
    'click .slides .actions .previous': 'onSlidesPreviousClick',
    'click .slides .actions .next': 'onSlidesNextClick',
    'click .thumbnails .actions .previous': 'onThumbnailsPreviousClick',
    'click .thumbnails .actions .next': 'onThumbnailsNextClick',
    'click .thumbnails .slide': 'onThumbnailsSlideClick',
    'click .dots .dot': 'onDotsClick',
    'mousedown .slides-inner, .thumbnails-inner': 'preventImageDrag',
  },

  /**
   * Whether a drag of the slides carousel is currently in progress.
   */
  slidesDragging: false,

  /**
   * Whether a drag of the thumbnails carousel is currently in progress.
   */
  thumbnailsDragging: false,

  /**
   * Record the previous slide, to track which direction a slide is being navigated.
   */
  previousSlide: 0,

  /**
   * Pixel value of the window width at and above which is considered desktop mode.
   */
  desktopMode: 980,

  /**
   * Create Siema sliders and set events.
   *
   * @param {jQuery} $gallery Gallery container.
   */
  initialize: function initialize($gallery) {
    this.$el = $gallery;
    this.$slidesInner = this.$el.find('.slides-inner');
    this.$slides = this.$slidesInner.find('.slide');
    this.$thumbnailsInner = this.$el.find('.thumbnails-inner');
    this.$thumbnails = this.$thumbnailsInner.find('.slide');
    this.$dots = this.$el.find('.dots .dot');
    this.$touchDetected = Modernizr.touchevents;

    this.$prev = this.$el.find('.slides .actions .previous');
    this.$prevThumb = this.$el.find('.thumbnails .actions .previous');
    this.$next = this.$el.find('.slides .actions .next');
    this.$nextThumb = this.$el.find('.thumbnails .actions .next');

    this.slidesSiema = new Siema({
      selector: this.$slidesInner[0],
      threshold: this.draggingThreshold,
      onInit: this.onSlidesSiemaInit.bind(this),
      onChange: this.onSlidesSiemaChange.bind(this),
      cursors: false,
    });

    if (this.$thumbnails.length) {
      this.thumbnailsSiema = new Siema({
        selector: this.$thumbnailsInner[0],
        threshold: this.draggingThreshold,
        perPage: this.thumbnailCount,
        cursors: false,
        draggable: this.$touchDetected,
      });
    }

    this.delegateEvents(this.events);
    // Cannot use Backbone for these events,
    // as they would not get called due to stop propogation in Siema
    // and are only binded if touch is detected
    if (this.$touchDetected) {
      this.$slidesInner.on('touchmove mousemove', this.onSlidesTouchMove.bind(this));
      this.$thumbnailsInner.on('touchmove mousemove', this.onThumbnailsTouchMove.bind(this));
    }
  },

  /**
   * For the given slide, load the image src and srcset attributes
   * from the respective data attributes for the slide and its adjacent n siblings
   * based on a given window.
   *
   * @param {number} slideIndex    Slide to load images for and of adjacent siblings.
   * @param {number} preloadWindow Number of slides either side of the given one
   *                               to load images for.
   */
  loadMedia: function loadMedia(slideIndex, preloadWindow) {
    let i = Math.max(0, slideIndex - preloadWindow);
    let length = Math.min(slideIndex + preloadWindow, this.$slides.length);
    let $media;

    for (; i <= length; i += 1) {
      $media = this.$slides.eq(i).parent().find('iframe, img, video');

      if ($media.attr('src') !== $media.data('src')) {
        $media.attr('src', $media.data('src'));
      }

      if ($media.attr('srcset') !== $media.data('srcset')) {
        $media.attr('srcset', $media.data('srcset'));
      }
    }
  },

  /**
   * Given the drag object from Siema, detect whether a drag is in progress
   * based on the supplied threshold.
   *
   * @param  {object} drag      Object from Siema representing a drag.
   * @param  {number} threshold Number of pixels of horizontal movement that constitutes a drag.
   * @return {boolean}          Whether a drag is in progress.
   */
  detectDragging: function detectDragging(drag, threshold) {
    if (!drag.endX) {
      return false;
    }

    return Math.abs(drag.startX - drag.endX) >= threshold;
  },

  /**
   * Copy the slide caption from the slide itself (hidden) to the caption container,
   * which appears below the dots but above the thumbnails
   * (therefore outside of the slides container).
   *
   * @param {number} slideIndex Slide to copy the caption of.
   */
  copyCaption: function copyCaption(slideIndex) {
    let caption = this.$slides.eq(slideIndex).find('figcaption').clone().children();

    this.$caption = this.$el.find('.caption');
    this.$caption.html('').append(caption);
  },

  /* Event Handlers */

  /**
   * Event when the Siema carousel initializes.
   */
  onSlidesSiemaInit: function onSlidesSiemaInit() {
    this.loadMedia(0, this.preloadSlideWindow);
    this.copyCaption(0);
    this.updateButtons(0);
    this.upgradeClickableElementsToBeAccessible();
    this.setTabindexForSlideDynamicElements(0, 0);
  },

  /**
   * Event when the Siema carousel slide changes.
   */
  onSlidesSiemaChange: function onSlidesSiemaChange() {
    this.loadMedia(this.slidesSiema.currentSlide, this.preloadSlideWindow);
    this.copyCaption(this.slidesSiema.currentSlide);
    this.updateButtons(this.slidesSiema.currentSlide);
    this.toggleActiveAccessibleSlide();

    if (this.$dots.length) {
      this.$dots.filter('.active').removeClass('active');
      this.$dots.eq(this.slidesSiema.currentSlide).addClass('active');
    }

    if (this.$thumbnails.length) {
      this.thumbnailsSiema.goTo(
        this.slidesSiema.currentSlide - Math.floor(this.thumbnailCount / 2),
      );

      this.$thumbnails.filter('.active').removeClass('active');
      this.$thumbnails.eq(this.slidesSiema.currentSlide).addClass('active');
    }

    if (this.slidesDragging) {
      this.trackSlideChange(
        'swipe',
        this.previousSlide < this.slidesSiema.currentSlide ? 'forward' : 'backward',
        'slide',
      );
    }

    this.previousSlide = this.slidesSiema.currentSlide;
  },

  /**
   * Event handler, when the next button for the slides carousel is clicked.
   */
  onSlidesNextClick: function onSlidesNextClick() {
    this.slidesSiema.next();

    this.trackSlideChange('tap', 'forward', 'arrow');
  },

  /**
   * Event handler, when the previous button for the slides carousel is clicked.
   */
  onSlidesPreviousClick: function onSlidesPreviousClick() {
    this.slidesSiema.prev();

    this.trackSlideChange('tap', 'backward', 'arrow');
  },

  /**
   * Event handler, when mouse or touch is being moved on a slide in the slides carousel.
   */
  onSlidesTouchMove: function onSlidesTouchMove(e) {
    this.slidesDragging = this.detectDragging(this.slidesSiema.drag, this.draggingThreshold);

    if (this.slidesDragging) {
      e.preventDefault();
    }
  },

  /**
   * Event handler, when the next button for the thumbnails carousel is clicked.
   */
  onThumbnailsNextClick: function onThumbnailsNextClick() {
    this.thumbnailsSiema.next(this.thumbnailCount);
  },

  /**
   * Event handler, when the previous button for the thumbnails carousel is clicked.
   */
  onThumbnailsPreviousClick: function onThumbnailsPreviousClick() {
    this.thumbnailsSiema.prev(this.thumbnailCount);
  },

  /**
   * Event handler, when the slide in the thumbnails carousel is clicked.
   */
  onThumbnailsSlideClick: function onThumbnailsSlideClick(e) {
    if (this.thumbnailsDragging) {
      return;
    }

    this.slidesSiema.goTo($(e.target).closest('.slide').parent().index());

    this.trackSlideChange('tap', null, 'thumbnail');
  },

  /**
   * Event handler, when mouse or touch is being moved on a slide in the thumbnails carousel.
   */
  onThumbnailsTouchMove: function onThumbnailsTouchMove(e) {
    this.thumbnailsDragging = this.detectDragging(
      this.thumbnailsSiema.drag,
      this.draggingThreshold,
    );

    if (this.thumbnailsDragging) {
      e.preventDefault();
    }
  },

  /**
   * Event handler, when a dot (indicating the available and active slides) is clicked.
   */
  onDotsClick: function onDotsClick(e) {
    this.slidesSiema.goTo($(e.target).index());

    this.trackSlideChange('tap', null, 'dot');
  },

  /**
   * Fire an event indicating that the slide was changed.
   *
   * @param {string} action    Action causing the slide to be changed (swipe, tap)
   * @param {string} direction Direction slide is being move in (forward, backward)
   * @param {string} element   Element that triggered the change (dot, slide, thumbnail)
   */
  trackSlideChange: function trackSlideChange(action, direction, element) {
    UserEvents.trigger('embedded_gallery.event.slide_change', {
      action,
      direction,
      element,
      slide: this.slidesSiema.currentSlide + 1,
    });
  },

  /**
   * Prevents image dragging when the window width is equal to or greater than the
   * given pixel value for desktopmode( currently 980px ) since dragging is disabled
   * on desktop widths with no touchevents detected.
   *
   * NOTE: We may want to look for a better imagedrag prevention because if you simply
   * narrow the window width under 980px on desktop, prevention is gone ¯\_(ツ)_/¯.
   *
   * @param {object} event Mouse down event
   */
  preventImageDrag: function preventImageDrag(e) {
    if (window.innerWidth >= this.desktopMode) {
      e.preventDefault();
    }
  },

  /**
   * Updates action buttons based on the current position of the slider.
   * @param {Number} current - The current slide index.
   */
  updateButtons: function updateButtons(current) {
    this.$prev[current === 0 ? 'addClass' : 'removeClass']('hidden');
    this.$prevThumb[current === 0 ? 'addClass' : 'removeClass']('hidden');
    this.$next[current >= this.$slides.length - 1 ? 'addClass' : 'removeClass']('hidden');
    this.$nextThumb[current >= this.$slides.length - 1 ? 'addClass' : 'removeClass']('hidden');
  },

  /**
   * Toggles current slide to be accessible,
   * while turns off accessibility to previous slide
   */
  toggleActiveAccessibleSlide: function toggleAccessibleSlide() {
    this.setTabindexForSlideDynamicElements(this.slidesSiema.currentSlide, 0);
    this.setTabindexForSlideDynamicElements(this.previousSlide, -1);

    // need a delay to not interfere with animation, and to load images for far jumps
    setTimeout(() => this.setFocusOnSlideMedia(this.slidesSiema.currentSlide), 300);
  },

  /**
   * Set tabindex for slide's dynamic elements
   * @param {Number} slideIndex
   * @param {Number} tabindex
   */
  setTabindexForSlideDynamicElements: function setSlideTabindex(slideIndex, tabindex) {
    this.$slides.eq(slideIndex).find('div').first().attr('tabindex', tabindex);
  },

  /**
   * Upgrades clickable elements to be accessible for
   * keyboard navigation with "space" and "enter"
   */
  upgradeClickableElementsToBeAccessible: function upgradeClickableElementsToBeAccessible() {
    const clickableAccessibleElements = [
      this.$prev,
      this.$next,
      this.$dots,
      this.$prevThumb,
      this.$nextThumb,
      this.$thumbnails,
    ];

    clickableAccessibleElements.forEach($elm =>
      $elm.on('keydown', this.accessibleKeyboardHandler),
    );
  },

  /**
   * Helper to make clickable elements to be accessible for
   * keyboard navigation with "space" and "enter"
   * @param {Object} e
   */
  accessibleKeyboardHandler: function accessibleKeyboardHandler(e) {
    // Enter and Space buttons
    if (e.keyCode === 13 || e.keyCode === 32) {
      e.preventDefault();
      e.target.click();
    }
  },

  /**
   * Sets focus on the slides media div by slideIndex
   * @param {number} slideIndex
   */
  setFocusOnSlideMedia: function setFocusOnSlideMedia(slideIndex) {
    this.$slides.eq(slideIndex).find('div').first().focus();
  },
});
