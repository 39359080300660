import { domLoad, windowLoad } from 'app/shared/utils';

/**
 * Loads media using the lazysizes lib - https://github.com/aFarkas/lazysizes
 */
const loader = {
  // queue of LQIPs to be loaded
  lqipQueue: [],
  // whether or not we are ready to load LQIPs
  lqipReady: false,
};

const allowedTemplateTypes = ['article', 'gallery'];

let observer;

/**
 * Load an image by manually triggering lazySizes's unveil.
 * @param {Element} el - Element to unveil
 */
loader.loadImage = function loadImage(el) {
  window.lazySizes.loader.unveil(el);
};

/**
 * Loads a low quality image placeholder by setting the background-image CSS
 * property of the LQIPs parent element.
 * @param {Element} el - LQIP element target.
 */
loader.loadLqip = function loadLqip(el) {
  let lqip = el.getAttribute('data-lqip');
  let parent = el.parentElement;
  if (parent) {
    parent.style.backgroundImage = 'url("' + lqip + '")';
    parent.style.backgroundSize = '100%';
  }
};

/**
 * Processes the LQIP queue, loading any LQIPs that have already been registered.
 */
loader.processLqips = function processLqips() {
  loader.lqipReady = true;
  loader.lqipQueue.forEach(loader.loadLqip);
};

/**
 * Handles lazysizes's `lazybeforeunveil` event. If the element being unveiled is
 * an LQIP, load it if we're ready. Otherwise register the LQIP to be loaded when
 * ready.
 * @param {Object} evt - lazybeforeunveil event object.
 */
loader.handleBeforeUnveil = function handleBeforeUnveil(evt) {
  let target = evt.target;
  if (!(target instanceof Element)) return;

  let lqip = target.getAttribute('data-lqip');

  if (lqip) {
    if (loader.lqipReady) {
      loader.loadLqip(target);
    } else {
      loader.lqipQueue.push(target);
    }
  }

  // Start observing picture tags for tracking
  if (target.tagName === 'PICTURE' && observer) observer.observe(target);
};

loader.initLazysizes = function initLazysizes(templateType) {
  // Add the beforeunveil event listener
  window.addEventListener('lazybeforeunveil', loader.handleBeforeUnveil);

  // on DocumentComplete, initialize lazy sizes
  domLoad(lazySizes.init);

  // on window load, begin loading LQIPs lower on the page
  windowLoad(loader.processLqips);

  if (!allowedTemplateTypes.includes(templateType)) return false;

  const setObserver = ({ createObserver }) => {
    // keep threshold as [0, 1] only trigger observe entry when hit those two retio
    // consider 100% as inview and 0% as out of view
    observer = createObserver({ threshold: [0, 1] });
    return observer;
  };

  return import('app/modules/inview/image-track-inview').then(setObserver);
};

export default loader;
