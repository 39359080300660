import Fb from 'app/shared/fb';
import GlimmerPlayer from 'app/modules/video-players/glimmerplayer';
import MosPlayer from 'app/modules/video-players/mosplayer';
import YoutubePlayer from 'app/modules/video-players/youtubeplayer';
import YoutubePlaylist from 'app/modules/youtubeplaylist';
import { getValue } from 'app/shared/utils';
import { loadResources } from 'app/shared/utilslegacy';

// instance privately
let embedFb = null;

// stores youtube playlists
let embedPlaylists = [];

export default {
  // This is a list of embeds that are GDPR eligble and require consent,
  // you can see a list of the GDPR providers, and which require consent
  // in core.php.
  windowRequiresGDPRConsent: window.requiresGDPRConsent || [],
  // begin embed provider functions
  // loads the election hub resource
  celtra: function celtra(el) {
    if (window.GDPR && !/C0003/.test(window.GDPR_CONSENT_GROUPS)) return false;

    const embedElement = $(el).find('.celtra-ad-v3');
    const embedId = embedElement.attr('data-celtra-id');
    if (embedId) {
      // Celtra Template
      const adTemplate =
        "<img src='data:image/png,celtra' style='display: none' onerror='" +
        '(function(img) {' +
        'var params = {"clickUrl":"","expandDirection":"undefined","preferredClickThroughWindow":"new","clickEvent":"advertiser","externalAdServer":"Custom","tagVersion":"4"};' +
        'var req = document.createElement("script");' +
        'req.id = params.scriptId = "celtra-script-" + (window.celtraScriptIndex = (window.celtraScriptIndex||0)+1);' +
        'params.clientTimestamp = new Date/1000;' +
        'params.clientTimeZoneOffsetInMinutes = new Date().getTimezoneOffset();' +
        'params.hostPageLoadId=window.celtraHostPageLoadId=window.celtraHostPageLoadId||(Math.random()+"").slice(2);' +
        `var src = (window.location.protocol == "https:" ? "https" : "http") + "://content.celtra.com/${embedId}/web.js?";` +
        'for (var k in params) {' +
        'src += "&amp;" + encodeURIComponent(k) + "=" + encodeURIComponent(params[k]);' +
        '}' +
        'req.src = src;' +
        'img.parentNode.insertBefore(req, img.nextSibling);' +
        '})(this);' +
        "'/>";

      $(embedElement).append(adTemplate);
    }
    return true;
  },

  // loads the election hub resource
  election: function election(el) {
    this.loadResource('election', el, this.loadElections);
  },

  // loads the facebook resource
  facebook: function facebook(el, callback) {
    let fb = this.getFb();

    if (fb) {
      fb.loadFacebookSDK(callback);
    }
  },

  // loads the instagram resource
  instagram: function instagram(el, callback) {
    this.loadResource('instagram', el, callback);
  },

  // loads the mediaos player
  mediaos: function mediaos(el, callback) {
    callback();
    return new MosPlayer({
      el: $(el).find('iframe'),
    });
  },

  glimmer: function glimmer(el, callback) {
    callback();
    return new GlimmerPlayer({
      el: $(el),
    });
  },

  // loads the pinterest resource
  pinterest: function pinterest(el, callback) {
    this.loadResource('pinterest', el, callback);
  },

  // loads the playbuzz resource
  playbuzz: function playbuzz(el, callback) {
    this.loadResource('playbuzz', el, function loaded() {
      let load = getValue('PlayBuzz.Feed.renderFeed', window, $.noop);

      load();

      callback.apply(callback, Array.prototype.slice.call(arguments));
    });
  },

  /**
   * Forms the URL params and modifies the editorial poll src before
   * loading an editorial poll resource
   *
   * @param {object} frame Iframe element
   * @param {Function} callback Callback to fire after iframe loads
   */
  poll: function poll(el, callback) {
    let frame = $(el).find('iframe').addBack('iframe')[0];
    let resizer = this.getIframeResize();

    this.loadIframe(frame, function loaded() {
      resizer({}, frame);
      callback();
    });
  },

  // loads the quiz embeds
  quiz: function quiz(el, callback) {
    this.loadResource('quiz', el, function loaded() {
      iFrameResize({}, document.getElementById('HMQuiz-iframe'));

      callback.apply([].slice.call(arguments));
    });
  },

  reddit: function reddit(el, callback) {
    this.loadResource('reddit', el, callback);
  },

  // loads the talkShopLive resource
  talkShopLive: function talkShopLive(el, callback) {
    if (window.GDPR && !/C0004/.test(window.GDPR_CONSENT_GROUPS)) {
      return false;
    }
    return this.loadResource('talkShopLive', el, callback);
  },

  // loads the tiktok resource
  tiktok: function tiktok(el, callback) {
    this.loadResource('tiktok', el, callback);
  },

  // loads the tumblr resource
  tumblr: function tumblr(el, callback) {
    this.loadResource('tumblr', el, callback);
  },

  // loads twitter resource
  twitter: function twitter(el, callback) {
    // load the twitter resource, providing a custom callback
    this.loadResource('twitter', el, function loaded() {
      // attempt to get a reference to twitter's load function
      let load = getValue('twttr.widgets.load', window, $.noop);
      // call the load function
      load();
      // call the callback
      callback.apply(callback, Array.prototype.slice.call(arguments));
    });
  },

  // loads vine resource
  vine: function vine(el, callback) {
    this.loadResource('vine', el, callback);
  },

  // loads the youtube iframe
  youtube: function youtube(el, callback) {
    // load iframe
    this.loadIframe(
      el,
      function loaded(iframe) {
        callback();
        // load resource
        this.loadResource('youtube', el, function resourceLoaded() {
          // initialize player
          return new YoutubePlayer({
            el: iframe,
          });
        });
      }.bind(this),
    );
  },

  // loads the youtube playlist
  youtubePlaylist: function youtubePlaylist(mediaLoader, el, callback) {
    this.loadIframe(
      el,
      function loaded() {
        callback();
        // If api is loaded, initialize player
        if (getValue('YT.loaded', window)) {
          this.setPlaylists([el]);
          window.onYouTubeIframeAPIReady();
          return;
        }
        // Push playlist to array
        this.setPlaylists(this.getPlaylists().concat(el));
        // If api has not been loaded, load resource and set callback
        if (!getValue('YT', window)) {
          // Callback for YT object
          window.onYouTubeIframeAPIReady = this.createPlaylists.bind(this, mediaLoader);
          // Load resource once
          this.loadResource('youtube', el);
        }
      }.bind(this),
    );
  },
  // end embed provider functions

  // begin utilities
  // External resources for each provider
  resources: {
    election: '//electionwidget.lakana.com/assets-compiled/pym.min.js',
    instagram: '//www.instagram.com/embed.js',
    pinterest: '//assets.pinterest.com/js/pinit.js',
    playbuzz: '//cdn.playbuzz.com/widget/feed.js',
    quiz: '//quizatio.us/assets/widget/1.0.js?body=1',
    reddit: '//embed.redditmedia.com/widgets/platform.js',
    talkShopLive: '//embed.talkshop.live/embed.js',
    tiktok: '//www.tiktok.com/embed.js',
    tumblr: '//secure.assets.tumblr.com/post.js',
    twitter: '//platform.twitter.com/widgets.js',
    vine: '//platform.vine.co/static/scripts/embed.js',
    youtube: '//www.youtube.com/iframe_api',
  },

  _GDPRConsentCookie: undefined,
  _CMPConsentGroups: 'C0001', // strictly necessary only

  /**
   * Gets the Consent Management Provider Consent Groups
   * - based on OptanonConsent cookie
   * - if cookie not present assume non-consent
   *
   * @return  {string} consent groups
   */
  getCMPConsentGroups() {
    // Get OpanonConsent cookie
    const onetrustCookieVal = document.cookie.match('[;]*s*OptanonConsents*=s*([^;]+)');

    if (onetrustCookieVal) {
      // OneTrust cookie found
      let CONSENT_GROUPS = '';

      let groups = onetrustCookieVal[1].match(/groups=(.*?)(&|$)/);
      if (groups) {
        groups = decodeURIComponent(groups[1]).split(',');
        for (let i = 0; i < groups.length; i += 1) {
          if (groups[i].split(':')[1] === '1') {
            CONSENT_GROUPS += (CONSENT_GROUPS ? ',' : '') + groups[i].split(':')[0];
          }
        }
      }
      this._CMPConsentGroups = CONSENT_GROUPS;
    }
    return this._CMPConsentGroups;
  },

  /**
   * Checks the Consent Management Provider Consent Groups
   * if certain groups are present return true
   *
   * @return  {boolean} consent status
   */
  checkCMPConsentStatus() {
    const groups = this.getCMPConsentGroups();
    this._GDPRConsentCookie = !!/C0002|C0003|C0004|C0005/.test(groups);
    return this._GDPRConsentCookie;
  },

  /**
   * Checks the Consent Management Provider Consent Groups
   * if certain groups are present return true
   *
   * @return  {boolean} consent status
   */
  checkCMPConsentCategory(category) {
    const groups = this._CMPConsentGroups || this.getCMPConsentGroups();
    const catRegEx = new RegExp(category);
    const categoryConsent = !!catRegEx.test(groups);
    return categoryConsent;
  },

  /* Manage consent cookie status via get and set because GDPR_CONSENT
   * can be undefined during initialization
   */
  get GDPRConsentCookie() {
    return this.checkCMPConsentStatus();
  },
  set GDPRConsentCookie(cookie) {
    this._GDPRConsentCookie = cookie;
  },

  /**
   * loadGiphy takes a Giphy embed and loads it. If site or conditions
   * are GDPR and there is no consent then isn't loaded.
   * Uses loadIframe to load it.
   *
   * @param  {object}   el       DOM Element. Can be <iframe> or contain <iframe>
   * @param  {Function} callback Callback function to execute.
   */
  loadGiphy: function loadGiphy(el, callback) {
    // Check if there is GDPR consent on GDPR conditions
    if (window.GDPR && !this.getProviderConsent('giphy')) {
      return false;
    }

    // Giphy is only an Iframe to load so using loadIframe to lazy load it
    return this.loadIframe(el, callback);
  },

  /**
   * loadIframe takes an element and, if it's an iframe, loads it. If
   * the element is not an iframe, it looks at the elements children
   * and uses the first iframe it finds.
   *
   * @param  {object}   el       DOM Element. Can be <iframe> or contain <iframe>
   * @param  {Function} callback Callback function to execute.
   */
  loadIframe: function loadIframe(el, callback) {
    // cache $(el)
    let $el = $(el);
    // get the iframe
    let iframe = $el.is('iframe') ? el : $el.find('iframe').get(0);

    // check for GDPR and consent if required
    if (window.GDPR && !this.getElementConsent(iframe)) {
      return;
    }

    // check for a data-src and set it
    if (iframe && iframe.hasAttribute('data-src')) {
      iframe.src = iframe.getAttribute('data-src');
    }

    callback(iframe);
  },

  /**
   * loadResource takes a provider key and attempts to use require to
   * load it's resource, then executes a callback.
   *
   * @param  {string}   provider Provider key in this.resources
   * @param  {object}   el       DOM Element being loaded
   * @param  {Function} callback Callback function to execute
   */
  loadResource: function loadResource(provider, el, callback) {
    // attempt to get the resource
    let resource = getValue(provider, this.resources);

    if (window.GDPR && !this.getProviderConsent(provider)) {
      return false;
    }

    // if there was no resource found, just execute the callback and return
    if (!resource) {
      return callback();
    }

    // pass the localized resource through require and execute the callback once it loads
    loadResources([this.localizeResource(resource)], callback);

    return true;
  },

  /**
   * Loads PYM election widgets by instantiating each one after their lib script
   * has been loaded. Prevents needing to output <script> tags in the body.
   *
   * @param {object} pym provider pym as an exported argument
   */
  loadElections: function loadElections(pym) {
    let $embed = $('.embed-election');

    if (!$embed.length) {
      return;
    }

    $embed.each(function each() {
      let $this = $(this);
      return new pym.Parent($this.data('id'), $this.data('content'), {});
    });
  },

  /**
   * localizeResource localizes a resource URL, replacing a "{locale}"
   * macro with a combination of the current locale's language and
   * country.
   *
   * @param  {string} resource Resource URL to be localized.
   *
   * @return {string}          Localized resource URL.
   */
  localizeResource: function localizeResource(resource) {
    let locale = [
      getValue('CURRENT_LOCALE.language', window, 'en'),
      getValue('CURRENT_LOCALE.country', window, 'US').toUpperCase(),
    ].join('_');

    return resource.replace('{locale}', locale);
  },

  // returns an Fb instance and stores it
  // for subsequent calls
  getFb: function getFb() {
    if (window.GDPR && !this.getProviderConsent('facebook')) {
      return false;
    }

    if (embedFb instanceof Fb) {
      return embedFb;
    }
    embedFb = new Fb();
    return embedFb;
  },

  // returns the private playlists array
  getPlaylists: function getPlaylists() {
    return embedPlaylists;
  },

  /**
   * Sets the playlists array
   * @param {array} elements Array content
   */
  setPlaylists: function setPlaylists(elements) {
    embedPlaylists = elements;
  },

  /**
   * Returns the iFrameresize constructor
   */
  getIframeResize: function getIframeResize() {
    return iFrameResize;
  },

  // Returns the youtube playlist constructor
  getYT: function getYT() {
    return YoutubePlaylist;
  },

  /**
   * Creates playlists based on playlists array
   *
   * @param  {object} mediaLoader MediaLoader instance
   *
   */
  createPlaylists: function createPlaylists(mediaLoader) {
    let playlists = this.getPlaylists();
    let YTPlaylist = this.getYT();
    // Loop through array for players that were added
    // before api was ready
    playlists.forEach(function forEach(element) {
      let playlist = new YTPlaylist({
        el: element,
        mediaLoader,
      });
      return playlist;
    });
  },

  /**
   * Gets GDPR consent status for a supported embed provider
   *
   * @param  {string}   provider Provider name of embed provider e.g. facebook, instagram, etc.
   *
   */
  getProviderConsent: function getProviderConsent(provider) {
    return this.windowRequiresGDPRConsent.indexOf(provider) >= 0 && this.GDPRConsentCookie;
  },

  /**
   * Gets GDPR consent status for generic iframes
   * consent status passed from tyrion as data attribute data-block-on-consent
   * if no data attribute consent is not required
   *
   * @param  {object}   el DOM Element being loaded
   *
   */
  getElementConsent: function getElementConsent(el) {
    return el && el.dataset.blockOnConsent ? this.GDPRConsentCookie : true;
  },
  // end utilities
};
