import { importScripts, getValue } from 'app/shared/utils';

/**
 * Get API URL
 *
 * @returns {string} API URL
 */
const getApiUrl = () => getValue('JOURNEY_URL', window);

/**
 * Check if JAM is enabled
 *
 * @returns {boolean} True if enabled; false if disabled
 */
const jamJourneyEnabled = () => getValue('HRST.site.jam-journey-enabled', window, false);

/**
 * Dispatch journey-init custom event
 *
 * If the journeyData object already exists, this indicates the ensighten script
 * fired the journey-init event before the renderer script was loaded.
 */
const jamJourneyInit = () => {
  const { journeyData } = window;
  // if journeyData exists, dispatch event
  if (journeyData) {
    window.dispatchEvent(
      new CustomEvent('journey-init', {
        detail: journeyData,
      }),
    );
  }
};

/**
 * Defines functionality for interacting with JAM (Journey Access Management) service
 */
const JamRenderer = {
  /**
   * Import and initialize the renderer script
   */
  init() {
    const apiUrl = getApiUrl();
    const isEnabled = jamJourneyEnabled();

    if (isEnabled && apiUrl) {
      importScripts(`${apiUrl}/js/renderer.js`).then(jamJourneyInit);
    }
  },
};

export default JamRenderer;
