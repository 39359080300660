import Debounce from 'app/shared/debounce';
import { addListener } from '../shared/utils';

// load video src according to different media query
// we do it b'c media query on video doesn't work
const loadVideo = (video, attr) => {
  // find and set the correct src according to media
  let mediaMap = {};
  for (let i = 0; i < video.children.length; i += 1) {
    mediaMap[video.children[i].media] = video.children[i].getAttribute('data-src');
  }
  // sort by bp size descendantly
  Object.keys(mediaMap)
    // media format -- "(min-width: {{ breakpoint }})"
    .sort((a, b) => parseFloat(b.substring(12)) - parseFloat(a.substring(12)))
    .some((key) => {
      // replace w with px (example: 600w -> 600px) to make matchMedia work
      // https://regex101.com/r/SuXa8t/2
      const bp = key.replace(/(\d+)(w)/gi, '$1px');

      // early exit when match and attribute is not set
      if (window.matchMedia(bp).matches && video.getAttribute(attr) !== mediaMap[key]) {
        video.setAttribute(attr, mediaMap[key]);
        return true;
      }
      return false;
    });
  video.load();
};

const handleGifVideoLazyload = (evt) => {
  if (evt.target.tagName === 'VIDEO' && evt.target.classList.contains('gif-video')) {
    loadVideo(evt.target, 'src');
  }
};

/**
 * TODO: improve this resize handler to only handle it
 * when viewport is changed to another breakpoint to short-circuit it earlier.
 * something like storing the previous/next available sizes in an attribute on the video tag.
 * e.g: video.dataset.prevNextMedia = "320-680"
 * or 1 attr for prev and 1 for next,
 * then on resize check if the new width is greater than next or lesser than prev.
 */
const handleResize = () => {
  const gifvideos = document.querySelectorAll('video.gif-video');
  gifvideos.forEach((video) => {
    loadVideo(video, 'src');
  });
};

const GifVideoLoader = () => {
  if (!document.querySelector('video.gif-video')) {
    return;
  }

  // get lazyloading gifs before setting the event listener in case of racing condition
  const loadingGifs = document.querySelectorAll('video.gif-video.lazyloading');
  addListener(window, 'lazybeforeunveil', handleGifVideoLazyload);

  loadingGifs.forEach((gif) => {
    if (!gif.src) {
      loadVideo(gif, 'src');
    }
  });

  // bind to resize and scroll
  Debounce.on('resize', handleResize, 'gif-video');
};

export default GifVideoLoader;
