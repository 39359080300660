import { Collections, Sections } from 'app/collections/sitemapcollection';

const SITEMAP_MAX_ITEMS_PER_PAGE = 500;
const SECTIONS = 'SECTIONS';
const COLLECTIONS = 'COLLECTIONS';

const SitemapTemplateView = Backbone.View.extend({
  el: '',
  items: [],
  page: 1,
  view: '',
  sitemap: null,

  initialize: function initialize() {
    // set listeners
    if (this.el) this.setListeners();
  },

  setListeners: function setListeners() {
    this.el.addEventListener('click', this.fetchItems.bind(this));
  },

  /**
   * fetches the next page of items from the server.
   *
   */
  fetchItems: async function fetchItems() {
    this.page += 1;
    const model = this.view === COLLECTIONS ? new Collections() : new Sections();
    model.setPage(this.page);
    this.setLoading(true);
    const response = await model.fetch();
    this.items.push(...response.data);
    this.render();
  },

  /**
   * render the items in the sitemap
   *
   */
  render: function render() {
    const html = this.items.map(item => this.createElement(item)).join('');
    this.setLoading(false);
    this.sitemap.innerHTML += html;

    // if there aren't more items to load, delete the load more button with its parent
    if (this.items.length < SITEMAP_MAX_ITEMS_PER_PAGE) {
      this.el.parentElement.remove();
    }

    this.items = [];
  },

  /**
   * set the loading icon when the items are fetching from the server
   *
   * @param  {boolean} isLoading - Wether or not we're fetching more items from the collection
   * endpoint.
   */
  setLoading: function setLoading(isLoading) {
    // if set to true, create the loading icon and hide the load more button
    if (isLoading) {
      const nodeLoading = document.createElement('div');
      nodeLoading.classList.add('lds-dual-ring');
      this.el.style.display = 'none';
      this.el.parentElement.appendChild(nodeLoading);
    } else {
      const nodeLoading = document.querySelector('.lds-dual-ring');
      this.el.parentElement.removeChild(nodeLoading);
      this.el.style.display = 'inline-block';
    }
  },

  /**
   * create a html element following the pattern in the sitemap
   *
   * @param  {string} title - item's title.
   * @param  {string} slug - item's slug.
   * @param  {Array} subsections - item's subsections, it's optional.
   */
  mainElement: function mainElement(title, slug, subsections = []) {
    const childElement =
      subsections?.length > 0
        ? `<ul class="sitemap-list"> ${subsections
          .sort((a, b) =>
            a._attributes.title.toLowerCase().localeCompare(b._attributes.title.toLowerCase()),
          )
          .map((subsection) => {
            const { title: subsectionTitle, slug: subsectionSlug } = subsection._attributes;
            return mainElement(subsectionTitle, `${title}/${subsectionSlug}`);
          })
          .join('')} </ul>`
        : '';
    return `<li class="sitemap-item"><a class="link" href="/${slug}/" title="${title}">${title}</a>${childElement}</li>`;
  },

  /**
   * return a html element depending on the item's properties
   *
   * @param  {object} item - Object of item data for creating a new html element.
   */
  createElement: function createElement(item) {
    const { title, slug, subsections = [] } = item._attributes;
    return this.mainElement(title, slug, subsections);
  },
});

const SitemapCollections = SitemapTemplateView.extend({
  el: '.load-more-collections',
  view: COLLECTIONS,
  items: [],
  page: 1,
  sitemap: Array.from(document.querySelectorAll('.sitemap-list')).slice(-1)[0],
});

const SitemapSections = SitemapTemplateView.extend({
  el: '.load-more-sections',
  view: SECTIONS,
  items: [],
  page: 1,
  sitemap: Array.from(document.querySelectorAll('.sitemap-list'))[0],
});

export default Backbone.View.extend({
  initialize: function initialize() {
    this.setVariables();
  },

  setVariables: function setVariables() {
    this.sections = new SitemapSections();
    this.collections = new SitemapCollections();
  },
});
