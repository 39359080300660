/**
 * GenericModel is a generic model used for various pages. It provides some
 * common, useful functionality.
 *
 * NOTE: This model is also used as the basis for other models.
 *       To extend it, require this module and extend via:
 *       `MyModel = GenericModel.extend( myModelOptions );`
 *
 * NOTE: This model expects it's attributes to be wrapped in a `data` object
 * This is to allow for more graceful error checking when using the model in an
 * underscore template
 * Without the wrap, undefined properties will always throw an error, whereas with the
 * wrap, underscore is more forgiving with missing object property accesses. I.e.
 *
 * <%= imNotDefined %> will throw an error without more complicated _.isUndefined() checks
 * <%= data.imNotDefined %> is more forgiving and allows for better error checking
 *
 * For more information, see
 *      https://github.com/jashkenas/underscore/issues/237
 *      http://www.position-absolute.com/articles/handling-variable-is-not-defined-with-underscore-template-engine/
 *
 * Extending models can not use this method by overriding the getAttributeFromData method
 */
export default Backbone.Model.extend({
  defaults: {
    _el: false,
  },

  // Used to return an attribute from a "data" attribute
  // NOTE: This ties in to the explanation in the docblock above
  getAttributeFromData: function getAttributeFromData(attribute) {
    return this.get('data') && Object.prototype.hasOwnProperty.call(this.get('data'), attribute)
      ? this.get('data')[attribute]
      : null;
  },

  // Used to easily set an attribute in the "data" attribute
  // NOTE: This ties in to the explanation in the docblock above
  setAttributeFromData: function setAttributeFromData(attribute, value) {
    let data = this.get('data') || {};

    data[attribute] = value;

    this.set('data', data);
  },
});
