import Debounce from 'app/shared/debounce';
import { create } from 'app/modules/inview';
import { addListener, getValue, windowLoad, noop } from 'app/shared/utils';
import { toggleFocus } from 'app/modules/focustrap';

/**
 * Additional functionality for the site-wide top navigation menu.
 */
export default class NavMenu {
  /**
   * Set up listeners for subscribe, follow and search buttons.
   */
  constructor(nav) {
    this.nav = nav;

    this.desktopMode = !Modernizr.mobile && !Modernizr.tablet;

    this.searchButtons = document.querySelectorAll('.nav-search-button');
    this.searchInput = document.querySelector('#search-input');
    this.scrollPosition = 0;

    this.searchClickEvent = addListener(
      this.searchButtons,
      'click',
      this.loadSearchOverlay.bind(this),
    );

    this.accountSignInClass = 'account-signed-in';

    this.accountDropdownContainer = document.querySelector('.account-dropdown-container');

    this.addAccountDropdownListeners();

    addListener(this.searchButtons, 'click', this.focusSearchInput.bind(this));

    addListener(
      this.nav.querySelector('.nav-menu-social'),
      ['mouseenter', 'mouseleave'],
      this.toggleSocial.bind(this),
    );

    addListener(
      this.nav.querySelector('.nav-menu-subscribe'),
      ['mouseenter', 'mouseleave'],
      this.toggleSubscribe.bind(this),
    );

    addListener(window, 'hashchange', this.onHashChange.bind(this));

    windowLoad(() => {
      this.navOffset = this.nav.offsetTop;
      Debounce.on('scroll', this.toggleSticky.bind(this));
    });
  }

  /**
   * Return an instance if the nav menu is present in the DOM.
   */
  static setup(selector = '.nav') {
    const nav = document.querySelector(selector);

    return nav ? new NavMenu(nav) : null;
  }

  /**
   * Updates currentHash property when hashchanges
   */
  onHashChange() {
    // some browsers include the # so get rid of it here for easier checks
    this.currentHash = window.location.hash.replace('#', '');
    if (!this.currentHash) {
      document.documentElement.scrollTop = this.scrollPosition;
    }
  }

  /**
   * Add event listeners for the account dropdown
   */
  addAccountDropdownListeners() {
    if (NavMenu.isNewNav() && this.accountDropdownContainer) {
      if (this.desktopMode) {
        addListener(
          this.accountDropdownContainer,
          ['mouseenter', 'mouseleave'],
          this.toggleAccountDropdown.bind(this),
        );
      } else {
        addListener(
          this.accountDropdownContainer.querySelector('.account-button'),
          'click',
          this.toggleAccountDropdown.bind(this),
        );

        const observer = create([noop, this.handleAccountDropdownOutOfView.bind(this)]);
        observer.observe(this.accountDropdownContainer.querySelector('.account-dropdown'));
      }
    }
  }

  /**
   * Toggle the account dropdown
   */
  toggleAccountDropdown(e) {
    if (this.isLoggedIn()) {
      e.preventDefault();
      const toggleValue = this.nav.classList.toggle('show-account-dropdown');
      toggleFocus('.show-account-dropdown .account-dropdown', toggleValue);
    }
  }

  /**
   * Close the account dropdown when scrolled out of view
   */
  handleAccountDropdownOutOfView() {
    this.nav.classList.remove('show-account-dropdown');
  }

  /**
   * Lazyload the search module, prevent the fallback href from being followed, and deactivate the
   * click event that triggered this.
   */
  loadSearchOverlay() {
    this.searchClickEvent.remove();

    import('app/modules/searchoverlay').then((searchoverlay) => {
      const SearchOverlay = searchoverlay.default;

      this.searchOverlay = new SearchOverlay();
    });
  }

  /**
   * Focus the input on the search overlay
   */
  focusSearchInput() {
    // wrapped in rAF to handle the overlay CSS transition
    requestAnimationFrame(() => this.searchInput.focus());
  }

  /**
   * Toggle the social menu on hover.
   */
  toggleSocial() {
    this.nav.classList.toggle('show-social');
  }

  /**
   * Toggle the subscribe menu on hover.
   */
  toggleSubscribe() {
    this.nav.classList.toggle('show-subscribe');
  }

  /**
   * Toggle stickiness of the nav menu when scrolled on desktop.
   * @param {int} currentY
   */
  toggleSticky(currentY) {
    const h = window.location.hash.replace('#', '');
    // maintains scroll position when hash is empty
    if (currentY === 0 && !h && this.currentHash) {
      document.documentElement.scrollTop = this.scrollPosition;
      return;
    }
    this.scrollPosition = currentY;
    // Needs to be called every time in case Modernizr wasn't loaded during construct
    if (!this.desktopMode) return;
    this.nav.classList.toggle('sticky', currentY > this.navOffset);
  }

  /**
   * Check if the user is logged in
   */
  isLoggedIn() {
    return this.accountDropdownContainer.classList.contains('account-signed-in');
  }

  /**
   * Check if new nav is enabled
   */
  static isNewNav() {
    return getValue(NavMenu.getNewNavFlagName(), window);
  }

  /**
   * Name of the SSO flag
   */
  static getNewNavFlagName() {
    return 'HRST.site.new-nav';
  }
}
