import inview from 'in-view';

const videoPlayersInView = [];

/**
 * Defines the inview functionality for VideoPlayer
 */
export default Backbone.View.extend({
  playerInview: null,

  autoplayCache: true,

  autoplayState: null,

  // Baseline options for inview
  inviewOptions: {
    threshold: 0.25,
  },

  /**
   * Initializes inview on player
   */
  initializeInView: function initializeInView() {
    // cache the html element for autoplay check
    this.$html = $('html');

    // Initialize inview
    // NOTE: inview is a singleton, do not initialize it using the 'new' prefix
    this.inview = inview(this.$el.get(), _.extend({}, this.inviewOptions), this.playerId);

    this.setInViewListeners();
  },

  /**
   * Sets the inview listeners
   */
  setInViewListeners: function setInViewListeners() {
    this.inview
      .on('enter', this.handlePlayerInView.bind(this))
      .on('exit', this.handlePlayerOutOfView.bind(this))
      .check();
  },

  // Begin gettters/setters

  /**
   * Gets the players in view array
   *
   * @return {array} Players in view
   */
  getPlayersInView: function getPlayersInView() {
    return videoPlayersInView;
  },

  // End getters/setters

  // Begin utilities

  /**
   * Checks if we're on desktop mode
   *
   * @return {bool} True if on desktop, false otherwise
   */
  isDesktop: function isDesktop() {
    return this.$html.hasClass('no-mobile no-tablet');
  },

  /**
   * Checks if player is set to autoplay
   *
   * @return {bool} True if enabled, otherwise false
   */
  isAutoplay: function isAutoplay() {
    // tests for autoplay param in url
    // https://regex101.com/r/BX3Waj/1
    let reg = new RegExp(/autoplay=(.*?(?=(?:&|$)))/g);
    // check for data attribute or url query parameter in the src
    let autoplay = this.$el.data('autoplay') || reg.exec(this.$el.attr('src'));

    if (_.isArray(autoplay) && autoplay.length >= 2) {
      autoplay = autoplay[1];
    }

    return autoplay === 'true' || ~~autoplay > 0;
  },

  /**
   * Loops through the players in view and checks if any are currently playing
   *
   * @return {bool}
   */
  isInviewPlaying: function isInviewPlaying() {
    let isPlaying = false;

    this.getPlayersInView().some(
      function some(player) {
        isPlaying = this.getPlayerById(player).isPlaying();
        return isPlaying;
      }.bind(this),
    );

    return isPlaying;
  },

  /**
   * Determines if we're enabled to autoplay
   *
   * @return {bool} True if player is set to autoplay and on desktop mode
   */
  isAutoplayEnabled: function isAutoplayEnabled() {
    if (this.adsFree) {
      return false;
    }

    if (this.autoplayCache && this.autoplayState !== null) {
      return this.autoplayState;
    }

    this.autoplayState = this.isAutoplay() && this.isAutoplayAllowed();

    return this.autoplayState;
  },

  /**
   * Determines if autoplay is allowed. Desktop is enough in most cases, but
   * Glimmer overrides this to allow autoplay even on mobile.
   *
   * @return {bool} True if player is on desktop mode
   */
  isAutoplayAllowed: function isAutoplayAllowed() {
    return this.isDesktop();
  },

  // End of utilities

  // Begin event handlers

  /**
   * Handles when the player element appears in view
   */
  handlePlayerInView: function handlePlayerInView() {
    let playersInView = this.getPlayersInView();
    let index = playersInView.indexOf(this.playerId);

    this.playerInview = true;

    if (index < 0) {
      this.getPlayersInView().push(this.playerId);
    }

    this.logger.log(this.playerId + ' in view');

    if (!this.isAutoplayEnabled()) {
      this.logger.log(this.playerId + ' is not enabled for autoplay', {
        desktop: this.isDesktop(),
      });
      return false;
    }

    // attempt to play the current player and pause all others
    try {
      this.playPlayer();
    } catch (err) {
      this.logger.warn('Player in view failed', {
        message: err,
        playerId: this.playerId,
      });
    }

    return true;
  },

  /**
   * Hook for child class to determine if any action needed in exit handler
   * Override in child class with exception logic
   */
  canExitView: function canExitView() {
    return true;
  },

  /**
   * Hook for child class to determine if any action needed in exit handler
   * Override in child class with exception logic
   * @returns {boolean}
   */
  isFullscreen: function isFullscreen() {
    return false;
  },

  /**
   * Handles when the player element goes out of view
   */
  handlePlayerOutOfView: function handlePlayerOutOfView() {
    if (this.canExitView() && !this.isFullscreen()) {
      let playersInView = this.getPlayersInView();
      let index = playersInView.indexOf(this.playerId);

      this.playerInview = false;

      this.logger.log(this.playerId + ' out of view');

      // remove player from array if it was in view
      if (index >= 0) {
        playersInView.splice(index, 1);
      }

      // attempt to pause the player
      try {
        this.pausePlayer();
        this.playNextPlayerInview();
      } catch (err) {
        this.logger.warn('Player out of view failed', {
          message: err,
          playerId: this.playerId,
        });
      }
    }
  },

  // End event handlers

  // Begin player action handlers

  /**
   * Plays next player in view
   */
  playNextPlayerInview: function playNextPlayerInview() {
    let playersInView = this.getPlayersInView();
    let currentIndex = playersInView.indexOf(this.playerId);
    let player;

    if (currentIndex >= 0) {
      playersInView.splice(currentIndex, 1);
    }

    // attempt to get the first (next) player
    player = this.getPlayerById(playersInView[0]);

    this.logger.log('Attempting to play next player in view:', {
      playerId: playersInView[0],
    });

    // if a player was found and has autoplay enabled
    // attempt to play it
    if (player && player.isAutoplayEnabled()) {
      player.playPlayer();
    }
  },

  // End player action handlers
});
