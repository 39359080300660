/**
 * Sitemap is the template collection used for managing then collections and sections model
 * in the sitemap.
 */
const Sitemap = Backbone.Collection.extend({
  page: 1,

  fetch: function fetch(options) {
    const params = {
      ...options,
      page: this.page,
    };

    return Backbone.Collection.prototype.fetch.call(this, {
      data: { ...params },
    });
  },

  setPage: function setPage(page) {
    this.page = page;
  },
});

/**
 * Collections is the collection used for managing collection model
 * in the sitemap.
 */
export const Collections = Sitemap.extend({
  // Ajax endpoint for Collections
  endpoint: 'ajax/collections',
});

/**
 * Sections is the collection used for managing section model
 * in the sitemap.
 */
export const Sections = Sitemap.extend({
  // Ajax endpoint for Sections
  endpoint: 'ajax/sections',
});
