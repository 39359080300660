import Logger from 'app/shared/logger';
import EmbeddedGalleryLoader from 'app/modules/media-loaders/embeddedgalleryloader';
import EmbedLoader from 'app/modules/media-loaders/embedloader';
import ImageLoader from 'app/modules/media-loaders/imageloader';
import { addListener } from 'app/shared/utils';

export default class MediaLoader {
  constructor(options = {}) {
    // class added on GDPR for nonconsent
    this.nonConsentClass = 'gdpr-nonconsent';

    // class added on GDPR for C0004
    this.nonConsentC0004 = 'gdpr-nonconsent-C0004';

    // If user has consented, toggle class
    if (window.GDPR) {
      document.body.classList.toggle(this.nonConsentClass, !EmbedLoader.GDPRConsentCookie);
      const categoryConsent = EmbedLoader.checkCMPConsentCategory('C0004');
      document.body.classList.toggle(this.nonConsentC0004, !categoryConsent);
    }

    this.options = options;

    this.setVariables();

    this.setListeners();
  }

  setVariables() {
    // channel name used for Radio channel, Logger, and Debounce bindings
    this.channelName = this.options.channelName || 'medialoader';

    // class to add to lazy media elements are unable to load
    this.errorClass = this.options.errorClass || 'lazyload-error';

    // class for lazy media elements
    this.mediaClass = this.options.mediaClass || 'media-loader';

    // lazyload classname
    this.lazyloadClass = 'lazyload';

    // lazyloaded classname
    this.lazyloadedClass = 'lazyloaded';

    // classname indicating that media is loaded via media loader
    this.mediaLoadedClass = 'media-loaded';

    // video player class
    this.videoPlayerClass = 'glimmerPlayer';

    /**
     * Selector Map
     * Keys in this map are selectors that will be used
     * to test elements using $.fn.is.
     * Values in the map modules that export functions
     * that take a single argument and can be used to
     * load an media element on the page.
     */
    this.selectorMap = this.options.selectorMap || {
      '.embed-celtra': EmbedLoader.celtra.bind(EmbedLoader),
      '.slide-media-instagram, .embed-instagram': EmbedLoader.instagram.bind(EmbedLoader),
      '.slide-media-pinterest, .embed-pinterest': EmbedLoader.pinterest.bind(EmbedLoader),
      '.slide-media-youtube, .embed-youtube': EmbedLoader.youtube.bind(EmbedLoader),
      '.embed-youtube-playlist': EmbedLoader.youtubePlaylist.bind(EmbedLoader, this),
      '.embed-election': EmbedLoader.election.bind(EmbedLoader),
      '.embed-facebook': EmbedLoader.facebook.bind(EmbedLoader),
      '.embed-giphy': EmbedLoader.loadGiphy.bind(EmbedLoader),
      '.embed-iframe': EmbedLoader.loadIframe.bind(EmbedLoader),
      '.embed-playbuzz': EmbedLoader.playbuzz.bind(EmbedLoader),
      '.embed-reddit': EmbedLoader.reddit.bind(EmbedLoader),
      '.embed-talkShopLive': EmbedLoader.talkShopLive.bind(EmbedLoader),
      '.embed-tiktok': EmbedLoader.tiktok.bind(EmbedLoader),
      '.embed-tumblr': EmbedLoader.tumblr.bind(EmbedLoader),
      '.embed-twitter': EmbedLoader.twitter.bind(EmbedLoader),
      '.embed-vine': EmbedLoader.vine.bind(EmbedLoader),
      '.embed-quiz': EmbedLoader.quiz.bind(EmbedLoader),
      '.embed-poll': EmbedLoader.poll.bind(EmbedLoader),
      '.hearstPlayer': EmbedLoader.mediaos.bind(EmbedLoader),
      '.glimmerPlayer': EmbedLoader.glimmer.bind(EmbedLoader),
      '.embed-gallery': EmbeddedGalleryLoader.gallery.bind(EmbeddedGalleryLoader, this),
      '.bg-image': ImageLoader.background.bind(ImageLoader),
      video: ImageLoader.default.bind(ImageLoader),
    };

    // set up a logger for this module
    this.logger = new Logger(this.channelName);
  }

  /**
   * Uses lazysizes 'lazybeforeunveil' event handlers that execute loaders
   * for each type of media.
   */
  setListeners() {
    addListener(window, 'inview.load', (e) => {
      this.loadMedia(e.detail);
    });

    document.addEventListener('lazybeforeunveil', (event) => {
      this.loadMedia(event.target);
    });

    // workaround for loading video player
    // if video player is loaded (with lazyloaded class)
    // before loadMedia listener set up, add lazyload back
    document.querySelectorAll(`.${this.videoPlayerClass}`).forEach((el) => {
      if (
        !el.classList.contains(this.mediaLoadedClass) &&
        el.classList.contains(this.lazyloadedClass)
      ) {
        el.classList.remove(this.lazyloadedClass);
        el.classList.add(this.lazyloadClass);
      }
    });
  }

  /**
   * handleMediaLoaded handles "loaded" announcements on the lazyloader channel.
   *
   * @param {object} el DOM node that has loaded.
   */
  handleMediaLoaded(el) {
    // indicate that media is loaded via medialoader
    el.classList.add(this.mediaLoadedClass);

    // log for debugging
    this.logger.log('Media Loaded', arguments);
  }

  /**
   * getLoaderForMedia takes an element and returns the most appropriate loader
   * it can find. Returns undefined if no loader could be found.
   *
   * @param  {object} el DOM node to retrieve a loader for.
   * @return {*}         Loader function that will load the node. Undefined
   *                     if no loader could be found.
   */
  getLoaderForMedia(el) {
    // placeholders
    let loader;

    // loop over selector map
    Object.keys(this.selectorMap).some((selector) => {
      // check to see if the el matches the selector
      if (el.matches(selector)) {
        // if it does, assign the loader and get out
        loader = this.selectorMap[selector];
        return loader;
      }
      return false;
    });

    return loader;
  }

  /**
   * loadMedia takes an element and attempts to get it's loader function the selectorMap.
   * If found, the element is run through the loader and a loading class is applied.
   *
   * @param {Element} el DOM node to be loaded.
   */
  loadMedia(el) {
    if (!(el instanceof Element)) return;

    // early exit when it is not a media needs special loader
    if (!el.classList.contains(this.mediaClass)) {
      return;
    }

    // attempt to get the loader
    let loader = this.getLoaderForMedia(el);

    // check for a valid loader
    if (typeof loader !== 'function') {
      this.logger.warn('No valid loader for asset', el, loader);

      // add the error class (and loaded class will be added via lazysizes)
      el.classList.add(this.errorClass);
      return;
    }

    // run the element through the loader
    loader(el, this.handleMediaLoaded.bind(this, el));
  }
}
