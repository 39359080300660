import EmbeddedGallery from 'app/modules/embeddedgallery';
import GenericModel from 'app/models/genericmodel';
import GenericModelView from 'app/modelviews/genericmodelview';
import MediaCollection from 'app/collections/mediacollection';
import UserEvents from 'app/shared/userevents';

export default {
  /**
   * If the number of slides is equal to or less than this value,
   * then show dots instead of thumbnails and hide the slide count.
   */
  lowSlideCount: 8,

  /**
   * Supported display templates
   *
   * @type {Object}
   */
  supportedDisplays: {
    list: '#gallery-list-template',
  },

  /**
   * Default selector of the gallery template for underscore.
   */
  defaultTemplateSelector: '#gallery-module-template',

  /**
   * Currently active galleries on the page.
   */
  galleries: [],

  gallery: function gall(medialoader, el, callback) {
    let $el = $(el);
    let $gallery = $el.find('div.embedded-gallery');
    let galleryId = $gallery.data('galleryId');
    let galleryTitle = $gallery.data('title');
    let galleryDisplay = $gallery.data('display');
    let gallerySuppressTitle = $gallery.data('suppressTitle');
    let galleryHideBrands = $gallery.data('hideBrands');
    let galleryHideVendors = $gallery.data('hideVendors');
    let galleryHideProsAndCons = $gallery.data('hideProsAndCons');
    let gallery = new MediaCollection();

    // Fetch the gallery data from /ajax/contentmedia,
    // load that in to the underscore template and replace
    // the embedded gallery placeholder.
    gallery
      .fetch({
        data: {
          id: galleryId,
        },
      })
      .then(
        function fetch(data) {
          let selector = this.supportedDisplays[galleryDisplay] || this.defaultTemplateSelector;
          // generate a model view, and pass it to an underscore template
          let $view = $(
            new GenericModelView({
              model: new GenericModel({
                lowSlideCount: this.lowSlideCount,
                galleryId,
                galleryDisplay,
                gallerySuppressTitle,
                galleryHideBrands,
                galleryHideVendors,
                galleryHideProsAndCons,
                isAdsFree: window.ADSFREE,
                title: galleryTitle,
                data,
              }),
              templateSelector: selector,
            }).render().el,
          );

          $el.replaceWith($view);

          // initialize embedded gallery module if display type is slider
          if (galleryDisplay === 'slider') {
            this.galleries.push(new EmbeddedGallery($view));
          }

          // run callback function passed in as argument
          callback();

          // trigger an event notifying third parties of newly rendered content
          UserEvents.trigger('contentLazyLoaded', { source: 'embedded-gallery' });
        }.bind(this),
      )
      .fail(function fail() {
        $el.remove();
      });
  },
};
