/**
 * MediaCollection is the collection used for managing content media models
 * in the Slideshow.js module.
 */
export default Backbone.Collection.extend({
  // Ajax endpoint for Content Media models
  endpoint: 'ajax/contentmedia',

  // apply custom options to fetch calls so initial collection
  // models are updated, but collection length is preserved
  fetch: function fetch(options) {
    return Backbone.Collection.prototype.fetch.call(
      this,
      _.extend(options, {
        remove: false,
      }),
    );
  },

  // takes a model id and returns whether or not the model has been loaded
  isModelLoaded: function isModelLoaded(id) {
    return !!this.get(id) && this.get(id).getAttributeFromData('loaded');
  },
});
