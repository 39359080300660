import Debounce from 'app/shared/debounce';
import Logger from 'app/shared/logger';
import VideoPlayerInView from 'app/modules/videoplayerinview';
import { getValue } from 'app/shared/utils';

/**
 * Stores all players mapping to its instance
 * This is needed to access and pause all players on the page
 * If/when a player starts playing.
 *
 * E.g:
 *
 * {
 *   "youtube-1": {Backbone.view}
 * }
 *
 * @type {Object}
 */
const players = {};

/**
 * Stores player counts by type
 *
 * E.g:
 *
 * {
 *   "youtube": 2,
 *   "mos": 1,
 * }
 *
 * @type {Object}
 */
const playerCounts = {};

/**
 * Stores the timeout for throttling
 *
 * @type {int}
 */
let timer = null;

export default VideoPlayerInView.extend({
  // Default variables
  // NOTE: To be overriden by a Player instance
  logger: new Logger('videoplayer'),
  player: {},
  playerType: 'player',

  playerId: null,

  // Used to separate between
  // user actions and programatic actions
  playbackAction: false,

  playerPausedByUser: false,

  // Base playback options
  playerActions: {},

  /**
   * Supported player states
   * Abstract multiple event names in a common state name
   * These names are used in each utilty method. i.e isPlaying = play
   *
   * E.g:
   *
   * {
   *  "play": ["playing","play","adPlay"],
   *  "pause" : ["pause","paused"],
   * }
   */
  supportedPlayerStates: {},

  /**
   * List of templates where autoplay in view initializes by default
   *
   * @type {Array}
   */
  supportedTemplates: ['article', 'listicle', 'recipe'],

  scrollLatency: 250,

  template: getValue('HRST.article.template', window),

  /**
   * Initializes a video player
   */
  initializePlayer: function initializePlayer() {
    // Add player to store with unique id as it gets rendered
    this.getPlayers()[this.setPlayerId()] = this;

    this.logger.log(this.playerId + ' has been added', players);

    if (this.isInViewEnabled()) {
      this.initializeInView();
    }

    Debounce.on(
      'scroll',
      this.onScrollStop.bind(this, this.handleScroll.bind(this), this.scrollLatency),
    );
  },

  // Begin gettters/setters
  /**
   * Sets player id based on type
   */
  setPlayerId: function setPlayerId() {
    // set new index with player type, add/increase player count
    // e.g: youtube-6
    this.playerId = [
      this.playerType,
      (playerCounts[this.playerType] = ~~playerCounts[this.playerType] + 1),
    ].join('-');
    // set player id on element for easier lookup/debug
    // NOTE: These will vary based on the order videos are registered
    this.$el.attr('data-vp-id', this.playerId);

    return this.playerId;
  },

  /**
   * Gets a player by an id
   *
   * @param {string} playerId Player id
   *
   * @return {mixed}          A player instance if found, otherwise undefined
   */
  getPlayerById: function getPlayerById(playerId) {
    return this.getPlayers()[playerId];
  },

  /**
   * Gets all players
   *
   * @return {object} Players object
   */
  getPlayers: function getPlayers() {
    return players;
  },

  /**
   * Abstracts the player state method
   *
   * @return {mixed} Player state or passed in state
   */
  getPlayerState: function getPlayerState() {
    // Set initial state
    let state = null;
    // attempt to get state from player
    try {
      state = this.getState();
    } catch (err) {
      this.logger.warn('Unable to get player state', {
        player: this.playerId,
        isReady: this.isReady(),
      });
    }
    // return original or modified state
    return state;
  },

  // End of getters/setters

  // Begin utilities

  /**
   * Throttles scroll event
   *
   * @param {function} callback Function to trigger
   * @param {integer}  latency  Timeout value
   */
  onScrollStop: function onScrollStop(callback, latency) {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(function timeout() {
      timer = null;
      callback();
    }, latency);
  },

  /**
   * Checks if in-view functionality is enabled for template
   *
   * @return {bool}
   */
  isInViewEnabled: function isInViewEnabled() {
    return this.supportedTemplates.indexOf(this.template) >= 0;
  },

  /**
   * Checks if player has been instantiated correcly
   * NOTE: This method is intended to be overwritten by its subclasses
   *
   * @return {bool}
   */
  isReady: function isReady() {
    return false;
  },

  /**
   * Checks if an event is supported in the player
   *
   * @param {string} event Event to check on the object
   * @param {string} state State name
   *
   * @return {bool}
   */
  isSupported: function isSupported(event, state) {
    // Get state from player
    if (_.isUndefined(state)) {
      state = this.getPlayerState();
    }
    // check if any of the states are supported
    return (this.supportedPlayerStates[event] || []).indexOf(state) >= 0;
  },

  /**
   * Checks if the player is currently playing
   *
   * @param {integer} state Optional state
   *
   * @return {bool}
   */
  isPlaying: function isPlaying(state) {
    return this.isSupported('play', state);
  },

  /**
   * Checks if the player is currently paused
   *
   * @param {integer} state Optional state
   *
   * @return {bool}
   */
  isPaused: function isPaused(state) {
    return this.isSupported('pause', state);
  },

  /**
   * Checks if the player has ended
   *
   * @param {integer} state Optional state
   *
   * @return {bool}
   */
  isEnded: function isEnded(state) {
    return this.isSupported('end', state);
  },

  /**
   * Checks if a player has been paused by the user
   *
   * @return {bool}
   */
  isPausedByUser: function isPausedByUser() {
    return this.playerPausedByUser;
  },

  // End of utilities

  // Begin event handlers

  /**
   * Pauses players if there are more than 1 playing after a scroll event
   */
  handleScroll: function handleScroll() {
    let currentlyPlaying = {};
    let length = 0;
    let playerInview;
    // Loop and add players currently playing
    _.toArray(this.getPlayers()).forEach(function forEach(player) {
      if (player.isPlaying()) {
        currentlyPlaying[player.playerId] = player;
        length += 1;
      }
    });
    // If there are more than 1 players playing at a time
    // extract a player that is in view (if any), and pause the rest
    if (length > 1) {
      playerInview = _.findWhere(currentlyPlaying, { playerInview: true }) || {};
      this.pauseAllPlayers(playerInview.playerId, currentlyPlaying);
      this.logger.log('Multiple players are currently playing', {
        pausing: _.keys(currentlyPlaying),
        keepPlaying: playerInview.playerId,
      });
    }
  },

  /**
   * Handles the state change event
   *
   * @param {string} state Changed state
   */
  handleStateChange: function handleStateChange(state) {
    let isSupported =
      [].concat.apply([], _.toArray(this.supportedPlayerStates)).indexOf(state) >= 0;
    let playingOutOfView;

    // If event is not supported, exit
    if (!isSupported) {
      return false;
    }

    const isPlaying = this.isPlaying(state);
    playingOutOfView = this.playerInview === false && (isPlaying || state === 'play');

    // If the event is in the same category as when changed programatically
    // or it's playing out of view, pause and exit
    if (this.isSupported(this.playbackAction, state)) {
      return false;
    } else if (playingOutOfView) {
      this.logger.log('player started playing out of view', {
        playerId: this.playerId,
        inView: this.playerInview,
        playbackAction: this.playbackAction,
        state,
      });
      this.playback('pause');
      this.playerPausedByUser = false;
      return false;
    }

    this.logger.log('User action on ' + this.playerId, {
      playbackAction: this.playbackAction,
      userAction: state,
      playerId: this.playerId,
    });
    this.playbackAction = false;

    if (this.isPaused(state)) {
      this.playerPausedByUser = true;
    } else if (isPlaying) {
      this.playerPausedByUser = false;
      this.pauseAllPlayers();
    } else if (this.isEnded(state)) {
      // when a video ends, prevent video to play again
      // and play next video in view
      this.playerPausedByUser = true;
      this.playNextPlayerInview();
    }

    return false;
  },

  // End event handlers

  // Begin player action handlers

  /**
   * function to check if GDPR consent modal is present on the page
   */
  gdprConsentModalIsShowing: function gdprConsentModalIsShowing() {
    // OneTrust Modal
    let oneTrustModalIsShowing = !!document.getElementById('onetrust-banner-sdk');
    // Evidon Modal
    let evidonModalIsShowing = window.evidon ? window.evidon.barrier !== undefined : false;
    return !!(oneTrustModalIsShowing || evidonModalIsShowing);
  },

  /**
   * Attempts to play a player
   */
  playPlayer: function playPlayer() {
    let isPlaying = this.isPlaying();
    let pausedByUser = this.isPausedByUser();
    let currentlyPlaying = this.isInviewPlaying();
    let gdprModalIsShowing = this.gdprConsentModalIsShowing();

    // log attempt
    this.logger.log('Attempting to play (all params should be false): ' + this.playerId, {
      playing: isPlaying,
      pausedByUser,
      currentlyPlaying,
    });
    if (!isPlaying && !pausedByUser && !currentlyPlaying && !gdprModalIsShowing) {
      this.pauseAllPlayers();
      this.playback('play');
    }
  },

  /**
   * Attempts to pause a player
   */
  pausePlayer: function pausePlayer() {
    let isPlaying = this.isPlaying();

    this.logger.log('Attempting to pause: ' + this.playerId, {
      playing: isPlaying,
    });

    if (isPlaying) {
      this.playback('pause');
    }
  },

  /**
   * Interface method for all player actions ( play, pause, stop, etc)
   *
   * @param {string} event Event action
   */
  playback: function playback(event) {
    // attempt to select from mapping, otherwise use shared actions
    // e.g: play, pause, stop
    let method = this.playerActions[event] || event;

    // attempt to fire method on player
    try {
      this.player[method]();
      // set flag to prevent onPlayerStateChange actions
      this.playbackAction = event;
    } catch (err) {
      this.logger.warn('Failed attempting to ' + method, {
        player: this.playerType,
        action: method,
      });
    }
  },

  /**
   * Pauses all players on the page
   *
   * @param {string} playerId     Player to be excluded from pausing
   * @param {array}  videoPlayers Array of players to pause
   */
  pauseAllPlayers: function pauseAllPlayers(playerId, videoPlayers) {
    // extend global players
    // as to not affect the original values
    let playersCopy = _.extend({}, videoPlayers || this.getPlayers());
    let id = playerId || this.playerId;

    // removes current player from loop
    if (id) {
      delete playersCopy[id];
    }

    // If there are no players, return
    if (_.isEmpty(playersCopy)) {
      return;
    }

    // loop through other players on the page and pause them
    // regardless if they are in view or not
    _.toArray(playersCopy).forEach(function forEach(player) {
      if (!player.isPaused()) {
        player.playback('pause');
      }
    });
  },

  // End player action handlers
});
