import GenericModel from 'app/models/genericmodel';
import { getRetailerName, isMagentoProduct } from 'app/shared/utils';

export default Backbone.View.extend({
  // ModelTemplate is just a reference to GenericModel
  // see that model's docblocks for details
  ModelTemplate: GenericModel,

  // Begin top-level functions
  initialize: function initialize() {
    // Set template selector from options with fallback
    this.templateSelector = this.options.templateSelector || '#generic-item-template';

    // Cache template query
    this.$template = $(this.templateSelector);

    // Set template
    this.template = _.template(this.$template.length ? this.$template.html() : '');

    // Check if _el already exists and set up view if so
    if (this.model.get('_el') !== false) {
      this.$el = this.model.get('_el');

      // Call render function, instructing not to set $el
      this.render(false);

      // NOTE: Exisiting views need to re-delegate events after setting up the $el
      this.delegateEvents(this.events);
    }
  },

  render: function render(setEl) {
    // Render template into $el if requested
    if (setEl !== false) {
      const templateData = _.extend(
        { getRetailerName, isMagentoProduct },
        this.model.toJSON() || {},
      );
      this.el = this.template(templateData);
      this.$el = $(this.el);

      this.model.set('_el', this.$el);
    }

    this.setVariables();

    this.setListeners();

    return this;
  },
  // End top-level functions

  // Begin setter functions
  setVariables: function setVariables() {
    // Set parent from options
    this.parent = this.options.parent || null;
  },

  setListeners: function setListeners() {
    // Listen to various model events
    this.listenTo(this.model, 'change', this.modelChanged);
    this.listenTo(this.model, 'remove', this.remove);
  },
  // End setter functions

  // Begin model event functions
  modelChanged: function modelChanged() {},
  // End model event functions
});
