import {
  windowLoad,
  getQueryParamObj,
  getCookieByName,
  addListener,
  parsedCookies,
} from 'app/shared/utils';
import DataLayer from 'app/modules/datalayer';

const skimlinkRedirectHost = 'go.redirectingat.com';

/**
 * Additional functionality for the skimlink redirect.
 * moved from https://github.com/Hearst-Digital/ADTECH_SITE_JS/blob/master/ensighten/skimlinks.js
 */
export default class Xcust {
  constructor() {
    const events = [
      'app.slideshow.slide-change',
      'load.app.shopamazon',
      'app.embedded_gallery.event.slide_change',
      'app.contentLazyLoaded',
    ];

    addListener(window, events, this.addSkimlinksTrackingData.bind(this, true));

    windowLoad(() => {
      // check if tracking data has been already added on an even, or runs on windowLoad
      if (!this.eventRun) {
        this.addSkimlinksTrackingData();
      }
    });

    this.sourceRef = document.URL || window.location.href;
    this.queryParam =
      'URLSearchParams' in window && typeof Object.fromEntries === 'function'
        ? Object.fromEntries(new URLSearchParams(window.location.search).entries())
        : getQueryParamObj(window.location.search);

    this.utmCookie = JSON.parse(getCookieByName('gpt_utm'));
    this.utmSource = this.getUtmVal('source');
    this.utmCampaign = this.getUtmVal('campaign');
    this.utmMedium = this.getUtmVal('medium');
  }

  addSkimlinksTrackingData(eventRun = false) {
    const that = this;

    if (eventRun) {
      this.eventRun = eventRun;
    }

    document
      // check every link
      // see. https://github.com/Hearst-Hatchery/mp-fre-theme-hdm/blob/master/components/BodyPostProcessor/routines/AbstractExternalLinkRoutine.php#L18
      .querySelectorAll('a[href^="http"]:not(.skimlink)')
      .forEach((link) => {
        let href = link.getAttribute('href');
        const hrefUrl = new URL(href);

        if (hrefUrl.host === skimlinkRedirectHost) {
          // keep actuality state of sref param
          if (hrefUrl.searchParams.has('sref') && hrefUrl.searchParams.get('sref') !== that.sourceRef) {
            hrefUrl.searchParams.set('sref', that.sourceRef);
            link.setAttribute('href', hrefUrl.toString());
          }

          // add skimlinks tracking data as "xcust" param to go.redirectingat.com links
          if (
            !link.hasAttribute('data-skimlinks-tracking') &&
            !hrefUrl.searchParams.has('xcust')
          ) {
            hrefUrl.searchParams.set('xcust', that.buildXcustData(link));
            link.setAttribute('href', hrefUrl.toString());
            link.classList.add('skimlink');
          }
        }
      });
  }

  /*
   * Builds xcust string from query or cookie
   */
  buildXcustData(link) {
    let fbclid = this.queryParam.fbclid || Xcust.getCookie('fbclid');
    let gclid = this.queryParam.gclid || Xcust.getCookie('gclid');
    let msclkid = this.queryParam.msclkid || Xcust.getCookie('msclkid');

    // Get referrer domain
    let refDomain = '';
    let refUrl = document.referrer;
    if (refUrl) {
      refDomain = refUrl.split('/')[2];
    }

    let contentId = DataLayer.getValue('content.id') || DataLayer.getValue('listing.id');
    let contentProductId = link.getAttribute('data-vars-ga-product-id') || '';
    let productRetailerId = link.getAttribute('data-vars-ga-product-retailer-id') || '';

    return (
      '[' +
      [
        'utm_source|' + this.utmSource,
        'utm_campaign|' + this.utmCampaign,
        'utm_medium|' + this.utmMedium,
        'gclid|' + gclid,
        'msclkid|' + msclkid,
        'fbclid|' + fbclid,
        'refdomain|' + refDomain,
        'content_id|' + contentId,
        'content_product_id|' + contentProductId,
        'product_retailer_id|' + productRetailerId,
      ].join('[')
    );
  }

  /*
   * Gets value from _amznClk cookie if corresponding key is found
   * key - string of key used in cookie value
   */
  static getCookie(key) {
    let amznClk = parsedCookies._amznClk;
    let result;
    if (amznClk) {
      result = amznClk.split('-');
      if (key === result[0]) {
        result.shift(1);
        return result.join('-');
      }
    }

    return '';
  }

  /*
   * Returns value from query or gpt_utm cookie if corresponding key is found
   * key - string of key used in query or cookie value
   */
  getUtmVal(key) {
    // Check for key query parameter
    let utmValue = this.queryParam['utm_' + key];
    if (utmValue) {
      return utmValue;
    }

    // Check for campaign property in 'gpt_utm' cookie
    if (this.utmCookie) {
      if (Object.prototype.hasOwnProperty.call(this.utmCookie, key)) {
        return this.utmCookie[key];
      }
    }

    // Default to empty string
    return '';
  }
}
