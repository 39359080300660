import { addListener } from 'app/shared/utils';

export default class MobileAdhesionUnit {
  /**
   * Instantiates the mobile adhesion unit view if the element is on the page.
   */
  static setup() {
    const el = document.getElementById('mobile-adhesion-unit');

    return el && new MobileAdhesionUnit(el);
  }

  constructor(el) {
    this.el = el;
    this.close = this.el.querySelector('.mobile-adhesion-unit-close-button');

    this.renderTimeout = 5000;
    this.timeUntilRejection = Date.now() + 10000;
    this.pubadsReady = new Promise(this.checkPubads.bind(this));

    this.pubadsReady.then(this.bindToRender.bind(this)).catch(this.showClose.bind(this));

    addListener(this.close, 'click', this.hideUnit.bind(this));
  }

  /**
   * Binds a function to pubads's slotRenderEnded event.
   */
  bindToRender() {
    window.googletag
      .pubads()
      .addEventListener('slotRenderEnded', this.handleSlotRendered.bind(this));
  }

  /**
   * Resolves a promise if the googletag pubads service is ready. Rejects
   * if not available after `timeUntilRejection`
   */
  checkPubads(resolve, reject) {
    const gt = window.googletag;

    if (gt && gt.pubadsReady) resolve();
    else if (Date.now() > this.timeUntilRejection) reject();
    else requestAnimationFrame(this.checkPubads.bind(this, resolve, reject));
  }

  /**
   * Shows the close button after a specified timeout when the right ad slot is rendered.
   * @param {Object} evt
   */
  handleSlotRendered(evt) {
    const slot = document.getElementById(evt.slot.getSlotElementId());

    if (slot && slot.parentElement && slot.parentElement.id === 'adAdhesion') {
      setTimeout(this.showClose.bind(this), this.renderTimeout);
    }
  }

  /**
   * Hides the element when the close button is clicked.
   */
  hideUnit() {
    this.el.style.display = 'none';
  }

  /**
   * Shows the close button.
   */
  showClose() {
    this.close.classList.add('active');
  }
}
