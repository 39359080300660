/**
 * TrackingPixels is used to load any tracking pixel
 * in the body of a page.
 */
export default Backbone.View.extend({
  el: 'body',

  initialize: function initialize() {
    // store variables
    this.setVariables();

    // load tracking pixels
    this.load();
  },

  // begin getters/setters
  setVariables: function setVariables() {
    this.$pixels = this.$('.tracking-pixel:visible');
  },

  // do the actual loading; replace [timestamp] with a cache busting
  // random number in the query string
  load: function load() {
    let pixel;
    let src;

    this.$pixels.not('.tracking-pixel--loaded').each(function each(i, el) {
      pixel = $(el);
      src = pixel.data('src');

      src = src.replace(/\[timestamp\]/i, new Date().getTime());

      pixel.attr('src', src).addClass('tracking-pixel--loaded');
    });
  },
});
