import Logger from 'app/shared/logger';
import VideoPlayer from 'app/modules/videoplayer';
import { loadResources } from 'app/shared/utilslegacy';
import MediaQueryEvent from 'app/shared/mediaqueryevent';
import { addListener } from 'app/shared/utils';

const { ADSFREE: adsFree } = window;

export default VideoPlayer.extend({
  playerType: 'glimmer',
  logger: new Logger('glimmerplayer'),
  supportedPlayerStates: {
    play: ['playing', 'play'],
    pause: ['pause', 'paused'],
    end: ['complete'],
  },

  inviewOptions: {
    threshold: 0.5,
  },

  CUSTOM_PROMO_POSITION: 17,

  /**
   * Initialize the Glimmer Player
   */
  initialize: function initialize() {
    // Return early if there are no matching elements
    if (!this.$el.length) {
      return;
    }

    this.setVariables();

    if (window.hearstPlayer) {
      this.setPlayer();
      return;
    }

    this.setListeners();
    this.loadAPI();
  },

  // Begin getters/setters

  setListeners: function setListeners() {
    this.channel.on('ready', this.setPlayer.bind(this));

    if (!_.isFunction(window.hearstPlayerCallback)) {
      window.hearstPlayerCallback = this.channel.trigger.bind(this.channel, 'ready');
      this.logger.log('hearstPlayerCallback has been set');
    }

    // listen for next content divider visible to turn off sticky for glimmer player, if necessary
    addListener(window, 'app.adjustVideoForNextContent', () => this.removeSticky());
  },

  removeSticky: function removeSticky() {
    const stickyWrapper = document.querySelector('.glimmer-sticky-wrapper');
    if (!stickyWrapper) {
      return;
    }

    // hide the header so that manually removing sticky wrapper class does not affect styling
    const stickyHeader = document.querySelector('.glimmer-sticky-header');
    if (stickyHeader) {
      stickyHeader.style.display = 'none';
    }

    // simulate clicking close button to trigger appropriate handlers
    // https://github.com/Hearst-Hatchery/glimmer/blob/45f78017df48c39bd1a7664d747daab46f11891d/src/stickyplayer/StickyPlayer.js#L101
    const closeButtonSvg = document.querySelector('.glimmer-close');

    if (closeButtonSvg) {
      closeButtonSvg.dispatchEvent(new Event('click'));
    }

    // remove sticky classes from wrapper and player to avoid this triggering again
    stickyWrapper.classList.remove('glimmer-sticky-wrapper');
    const stickyPlayer = stickyWrapper.querySelector('.glimmer-sticky-player');
    stickyPlayer.classList.remove('glimmer-sticky-player');
  },

  setVariables: function setVariables() {
    this.channel = Backbone.Radio.channel(this.playerType);
    this.mediaQueryEvent = MediaQueryEvent;

    this.playerURL = window.PLAYER_URL;
    this.adsFree = adsFree;
    this.player = false;

    // used to to track if we have already automatically muted this player
    this.autoMuted = false;

    this.playerActions = {
      play: 'play',
      pause: 'pause',
    };

    // sticky player related
    this.stickyEnabled = this.isStickyEnabled();
    this.forceSticky = this.$el.data('force-sticky');
  },

  setPlayer: function setPlayer() {
    if (this.isReady()) {
      return this.player;
    }

    // sets up the individual player instances and wires up any events
    // that need direct access to glimmer
    const playerInstance = this.getPlayerInstance(this.$el);
    this.setupPlayerInstance(playerInstance);

    // Attempt to initialize the player
    try {
      this.initializePlayer();
    } catch (err) {
      this.logger.warn('failed to initialize the player wrapper module', {
        player: this.player,
      });
    }

    if (this.stickyEnabled) {
      this.setUpStickyPlayer(playerInstance);
    }

    if (this.adsFree) {
      this.$el.attr('data-disable-ads', true);
    }

    this.channel.trigger('player-loaded', this);

    return this.player;
  },

  setUpStickyPlayer: function setUpStickyPlayer(playerInstance) {
    const containerSelector = this.$el.data('sticky-container');
    const containerEl = containerSelector && document.querySelector(containerSelector);
    const containerClass = 'glimmer-sticky-container';
    const stickyOptions = containerEl
      ? {
        onEnter: () => containerEl.classList.add(containerClass),
        onExit: () => containerEl.classList.remove(containerClass),
      }
      : {};

    if (this.forceSticky) {
      stickyOptions.forceSticky = true;
    }

    playerInstance.initStickyPlayer(stickyOptions);
  },

  /**
   * Lifecycle method for helping start up a single player instance
   */
  getPlayerInstance: function getPlayerInstance($el) {
    const api = this.getPlayerApi();
    const position = $el.data('position');
    const el = $el.get(0);
    const isVideohub = !!el.getAttribute('data-hub-embed-id');
    const video = $el.find('.video-js');

    let params = {};
    if (position !== this.CUSTOM_PROMO_POSITION && !this.forceSticky && !isVideohub) {
      // we want to allow custom promo slots to autoplay as normal, since
      // they are not handled by normal autoplay in view logic
      // as well as force sticky player slots
      params = { autoplay: false };
    }

    if (this.adsFree) {
      params = { autoplay: false };
    }

    // if video exists, find instance in hrstPlayer
    if (video.length && window.hearstPlayer) {
      const videoId = video.attr('id');
      let playerInstance;
      window.hearstPlayer.instances.some((instance) => {
        const isPlayer = instance.player.id() === videoId;
        if (isPlayer) playerInstance = instance;
        return isPlayer;
      });
      return playerInstance;
    }

    return api.initPlayerElement(el, params);
  },

  /**
   * Sets up the player instance and any necessary events that need to be wired up to
   * handle autoplay in view functionality
   */
  setupPlayerInstance: function setupPlayerInstance(instance) {
    this.playerInstance = instance;
    this.player = instance.player;
    this.events = instance.events;

    this.events.on('pause', this.onPlayerStateChange.bind(this, this.player, 'pause'));
    this.events.on('play', this.onPlayerStateChange.bind(this, this.player, 'play'));
    this.events.on('ended', this.onPlayerStateChange.bind(this, this.player, 'ended'));
  },

  getPlayerApi: function getPlayerApi() {
    return window.hearstPlayer;
  },

  // End getters/setters

  // Begin utilities

  loadAPI: function loadAPI() {
    return loadResources([this.playerURL]);
  },

  /**
   * Returns the state of the player. Has more complex logic than
   * MOSPlayer because videojs does not have a singular state method.
   */
  getState: function getState() {
    const ready = this.isReady();
    const playing = !this.isPaused();
    const ended = this.isEnded();

    if (ended) {
      return 'complete';
    }

    if (ready && playing) {
      return 'playing';
    }

    return 'paused';
  },

  /**
   * Checks if player is ready
   *
   * @return {bool} True, if player is ready
   */
  isReady: function isReady() {
    // only tell us that we are far enough in to be able to call this. We don't care about
    // the status
    // TODO: add a better api/state method for this
    return this.player.readyState;
  },

  /**
   * Checks if player is paused
   *
   * @param {string} state the player is trying to go to
   * @return {bool} True, if player is paused
   */
  isPaused: function isPaused(state) {
    // an ended player should respond to 'ended' rather than paused in the
    // lifecycle events logic
    if (this.isEnded()) {
      return false;
    }

    const ready = this.player.readyState && this.player.readyState() > 1;
    const isNotPlaying = !this.isPlaying();

    // we don't want to say we are paused if we are about to play
    return state !== 'play' && ready && isNotPlaying;
  },

  /**
   * @return {bool} True, if player is playing or 'about-to-play'.
   */
  isPlaying: function isPlaying(state) {
    return this.playerInstance.isPlaying() || state === 'play';
  },

  /**
   * Checks if player is stopped playing
   *
   * @return {bool} True, if player is stopped playing
   */
  isEnded: function isEnded() {
    // TODO: add a player API method to support this
    return this.player.ended();
  },

  /**
   * Allows autoplay everywhere for glimmer
   *
   * @return {bool} True
   */
  isAutoplayAllowed: function isAutoplayAllowed() {
    // TODO: add a player API method to support this
    return this.playerInstance.settings.imaSdkAvailable;
  },

  // End utilities

  // Begin event handlers

  /**
   * Proxies to handleStateChange passing the state value
   *
   * @param {html} player Player element
   * @param {string} state  State the player has changed to
   */
  onPlayerStateChange: function onPlayerStateChange(player, state) {
    this.handleStateChange(state);
  },

  // End event handlers

  /**
   * Try to pause the player, without including the ad.
   */
  pause: function pause() {
    if (!this.playerInstance) {
      return;
    }

    this.playerInstance.pauseContent();
  },

  /**
   * Used to play the player. Only mutes the player once, as after the first
   * interaction with the browser element control is given to the user.
   */
  play: function play() {
    if (!this.playerInstance) {
      return;
    }

    // only try to mute once, since if the user unmuted manually we don't
    // want to step on their toes
    if (!this.autoMuted) {
      this.playerInstance.play(true);
      this.autoMuted = true;
      return;
    }

    this.playerInstance.play();
  },

  isStickyEnabled: function isStickyEnabled() {
    if (this.adsFree) {
      return false;
    }

    // FIXME: we expect this to be a temporary solution to prevent the sticky
    // player from showing up when there are subscription banners visible.
    if (window.pianoOfferDisplayed) {
      return false;
    }

    if (this.mediaQueryEvent.request('isGte', 'desktop')) {
      return this.$el.data('sticky');
    }

    return this.$el.data('sticky') && window.MOBILE_STICKY_PLAYER_ENABLED;
  },

  /**
   * Interface method for all player actions ( play, pause, stop, etc)
   *
   * @param {string} event Event action
   */
  playback: function playback(event) {
    // attempt to select from mapping, otherwise use shared actions
    // e.g: play, pause, stop
    let method = this.playerActions[event] || event;

    // attempt to fire method on player
    try {
      this[method]();
      // set flag to prevent onPlayerStateChange actions
      this.playbackAction = event;
    } catch (err) {
      this.logger.warn('Failed attempting to ' + method, {
        player: this.playerType,
        action: method,
      });
    }
  },

  /**
   * Sticky players cannot exit view, so do not follow through the exit handler
   */
  canExitView: function canExitView() {
    return !this.isStickyEnabled();
  },

  /**
   * If the player is fullscreen don't follow through with the exit handler
   * @returns {boolean}
   */
  isFullscreen: function isFullscreen() {
    return this.player.isFullscreen();
  },
});
