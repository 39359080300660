import Logger from 'app/shared/logger';

const { dispatchEvent } = window;

export default {
  // namespace is prefixed for all events
  namespace: 'app',
  logger: null,

  /**
   * trigger takes an event name and triggers the event with any
   * extra arguments passed in. If the event name isn't a string, a
   * warning is logged and false is returned. This is useful for
   * passing in values being retrieved from a constants file.
   * e.g.
   * UserEvents.trigger( Constants.getConstant( "EVENT_NAME" ) )
   * UserEvents.trigger( Utils.getValue( "EVENT_NAME",))
   *
   * @param  {string} name Name of the event.
   * @param {object} detail Data sent to the event
   * @return {this}        Returns self to allow chaining.
   */
  trigger(name, detail = {}) {
    // if the logger hasn't been created, create it once
    if (!this.logger) {
      this.logger = new Logger('UserEvents');
    }

    if (typeof name !== 'string') {
      this.logger.warn('Invalid User Event', name);
      return this;
    }

    // check if the first part of the event name
    // is the namespace
    if (!name.startsWith(`${this.namespace}.`)) {
      // if not, prefix the namespace to name
      name = `${this.namespace}.${name}`;
    }

    // Pass custom event for listeners attached with addEventListener
    dispatchEvent(new CustomEvent(name, { detail }));

    // Log the event for debugging
    this.logger.log(name, detail);

    return this;
  },
};
