import { addListener, importScripts } from 'app/shared/utils';

/**
 * Loads the video player automatically on desktop
 * On mobile/tablet, requires a click on an image placeholder
 */
export default class VideoLoader {
  constructor(selector = '.video-loader') {
    this.selector = selector;
    this.video = document.querySelector(this.selector);
    this.placeholder = this.video ? this.video.querySelector('.placeholder') : null;

    if (!this.placeholder) return;

    this.loadEvent = new CustomEvent('inview.load', {
      detail: this.video,
    });

    if (Modernizr.mobile) {
      this.clickEvent = addListener(this.placeholder, 'click', this.loadPlayer.bind(this));
    } else {
      this.loadPlayer();
    }
  }

  /**
   * Initializes the player on click and dispatches an inview event
   */
  initPlayer() {
    window.hearstPlayer.initPlayerElement(this.video);
    window.dispatchEvent(this.loadEvent);
  }

  /**
   * Loads the player script and triggers inview
   */
  loadPlayer(event) {
    if (event) this.clickEvent.remove();
    this.video.classList.add('media-loader');
    const callback = () => {
      if (event) {
        if (window.hearstPlayer) {
          this.initPlayer();
        } else if (!window.hearstPlayerCallback) {
          window.hearstPlayerCallback = this.initPlayer.bind(this);
        }
      } else {
        // only add lazyload to use lazysizes for lazy loading on non-mobile
        // mobile will use custom event to load video player
        this.video.classList.add('lazyload');
      }
    };

    if (!window.hearstPlayer) {
      importScripts(window.PLAYER_URL).then(callback);
    } else {
      callback();
    }
  }
}
