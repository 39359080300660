import LazysizesLoader from 'app/modules/lazysizesloader';
import MediaLoader from 'app/modules/medialoader';
import Debounce from 'app/shared/debounce';
import Logger from 'app/shared/logger';
import { ensureInstanceOf, preventEventActions } from 'app/shared/utils';

/**
 * ClickToPlay defines functionality for Click-to-Play animated images.
 */
export default Backbone.View.extend({
  el: 'body',

  events: {
    'click .click-to-play, .click-to-play-animated, .click-to-play-button': 'handleMediaClicked',
  },

  initialize: function initialize() {
    this.setVariables();
    this.setListeners();

    // call handleViewableMedia once initially to play any
    // media that might need playing at the top of
    // the page
    this.handleViewableMedia();
  },

  setVariables: function setVariables() {
    this.overrideDefaults({
      // active class
      activeClass: 'active',
      // data key where to store an image's animated counterpart
      animatedKey: 'animated',
      // selector for click-to-play static media
      clickToPlaySelector: '.click-to-play',
      // selector for click-to-play animated media
      clickToPlayAnimatedSelector: '.click-to-play-animated',
      // attribute where we'll store relevant data on each media element
      dataAttribute: 'click-to-play',
      // width of the window considered "desktop"
      desktopMode: 980,
      mediaLoader: null,
    });

    // reset the `$media` collection
    this.resetMediaCollection();

    // set up a logger
    this.logger = new Logger('ClickToPlay');

    // set up a media loader
    this.mediaLoader = ensureInstanceOf(this.mediaLoader, MediaLoader);

    // set the initial state
    this.state = this.getInitialState();
  },

  setListeners: function setListeners() {
    // bind to resize and scroll
    Debounce.on('resize', this.handleResize.bind(this), 'click-to-play');
    Debounce.on('scroll', this.handleScroll.bind(this), 'click-to-play');
  },

  getInitialState: function getInitialState() {
    return {
      scrollY: window.pageYOffset,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    };
  },

  /**
   * handleMediaClicked toggles the media that was clicked.
   *
   * @param {object} evt jQuery event object.
   */
  handleMediaClicked: function handleMediaClicked(evt) {
    const targetMedia = evt.currentTarget;

    // check if target is clickable image. return to keep clickable image functionality
    const closetHref = targetMedia.closest('a');
    if (closetHref && closetHref.className === 'clickable-image-link' && closetHref.href) return;

    // prevent other events from being fired
    preventEventActions(evt);

    // get a media element from the event object and call toggleMedia on it
    this.toggleMedia(this.getMediaFromEvent(evt));
  },

  /**
   * handleResize sets the state of the window dimensions and checks
   * any media that need to be toggled.
   *
   * @param  {number} lastWidth  Last recorded window width.
   * @param  {number} lastHeight Last recorded window height.
   */
  handleResize: function handleResize(lastWidth, lastHeight) {
    // set the state
    this.state.windowWidth = lastWidth;
    this.state.windowHeight = lastHeight;

    // handle any media in view
    this.handleViewableMedia();
  },

  /**
   * handleScroll sets the scroll state and checks any media that
   * need to be toggled on/off.
   *
   * @param  {number} lastScrollY Last recorded window.pageYOffset
   */
  handleScroll: function handleScroll(lastScrollY) {
    // set the state
    this.state.scrollY = lastScrollY;

    // handle any media in view
    this.handleViewableMedia();
  },

  /**
   * handleViewableMedia checks media in the $media collection to
   * see if they're in view. Any playing media out of view is toggled
   * off regardless of viewport. In desktop mode, media that come
   * into view are automatically toggled on.
   */
  handleViewableMedia: function handleViewableMedia() {
    // array of elements to toggle
    let mediaToToggle = [];

    // loop over the media elements
    this.$media.each(
      function each(i, el) {
        let $media = $(el);

        // if the media is playing, but not in view.. stop it
        if (
          (this.isPlaying($media) && !this.isInView($media)) ||
          // if the media is stopped, but is in view.. play it
          // this should only happen in desktop mode
          (this.isDesktopMode() && this.isInView($media) && !this.isPlaying($media))
        ) {
          mediaToToggle.push($media);
        }
      }.bind(this),
    );

    // play the media we should play
    mediaToToggle.forEach(this.toggleMedia.bind(this));
  },

  /**
   * getMediaFromEvent checks to see if the current target for the given event
   * is a click-to-play media. If not, it's assumed that this is the button and
   * siblings are checked instead.
   *
   * @param  {object} evt jQuery event object.
   * @return {object}     jQuery media element.
   */
  getMediaFromEvent: function getMediaFromEvent(evt) {
    // get the target of the event
    let $target = $(evt.currentTarget);

    // if the target is a click-to-play, return it.. otherwise look to siblings
    return $target.is(this.clickToPlaySelector)
      ? $target
      : $target.siblings(this.clickToPlaySelector);
  },

  /**
   * isDesktopMode checks the window width against the desktop
   * mode property.
   *
   * @return {boolean} Whether or not we're in desktop mode.
   */
  isDesktopMode: function isDesktopMode() {
    return this.state.windowWidth >= this.desktopMode;
  },

  /**
   * isInView checks to see if part of the given element is in the
   * browser's viewport.
   *
   * @param  {object}  $media jQuery media element.
   *
   * @return {boolean}        Whether or not the element is in view.
   */
  isInView: function isInView($media) {
    // top of the element
    let elTop = $media.offset().top;
    // bottom of the element
    let elBottom = elTop + $media.outerHeight();
    // top of the window
    let windowTop = this.state.scrollY;
    // bottom of the window
    let windowBottom = windowTop + this.state.windowHeight;

    // return true if any part of the media is in view
    return (
      (elTop >= windowTop && elTop <= windowBottom) ||
      (elBottom <= windowBottom && elBottom >= windowTop) ||
      (elTop <= windowTop && elBottom >= windowBottom)
    );
  },

  /**
   * isPlaying looks at the given media element to see if it contains
   * the playing class.
   *
   * @param  {object}  $media jQuery media element.
   *
   * @return {boolean}        Whether or not the media element is playing.
   */
  isPlaying: function isPlaying($media) {
    let $animated = $media.data(this.animatedKey);

    return $animated instanceof $ && $animated.hasClass(this.activeClass);
  },

  /**
   * resetMediaCollection resets the $media collection.
   */
  resetMediaCollection: function resetMediaCollection() {
    // set the $media property using the click-to-play selector
    this.$media = this.$(this.clickToPlaySelector).each(
      function each(i, el) {
        // attempt to get an animated counterpart for each element
        let $el = $(el);
        let $animated = $el.siblings(this.clickToPlayAnimatedSelector);

        // if we found one, set a data attribute for easy access
        if ($animated.length > 0) {
          $el.data(this.animatedKey, $animated);
        }
      }.bind(this),
    );
  },

  /**
   * toggleMedia swaps animated and placeholder image sources and classes on
   * the given media element.
   *
   * @param {object} $media jQuery media element
   */
  toggleMedia: function toggleMedia($media) {
    // get a bool that tells us whether or not the media is currently playing
    let playing = this.isPlaying($media);
    let $animated = $media.data(this.animatedKey);

    // if we couldn't find an animated counterpart for this
    // media element, get out now
    if ($animated === undefined || $animated.length < 1) {
      this.logger.log('No animated element found for click-to-play element: ', $media);
      return;
    }

    // log for devs
    this.logger.log(playing ? 'Stopping' : 'Playing', $media);

    // toggle the active class on the media and animated elements
    $media.toggleClass(this.activeClass);
    $animated.toggleClass(this.activeClass);

    LazysizesLoader.loadImage($animated.get(0));
  },
});
