/**
 * Ensures that an input DOM element is either already a jQuery
 * object or converts it to one
 *
 * @param  {mixed}  el     Either a dom node or a jquery object
 * @param  {jquery} jquery An optional reference to jquery, useful when the
 *                         library that is calling this function has a
 *                         different version of `$` than `mp-bower-assets`
 *
 * @return {jquery}        The el, wrapped in a `$` object
 */
const ensureJqueryObject = (el, jquery) => {
  jquery = jquery || $;

  return el instanceof jquery ? el : jquery(el);
};

/**
 * Method to download a resource asynchronously using ajax.
 * @param {[string]} resource URLs to resources, such as a .js files
 * @param {callable} callback to be invoked when the resources are loaded
 * @param {callable} callback to be invoked on error
 */
const loadResources = (resources, callback, err) => {
  const errorCallback = err || $.noop;

  return $.ajax({
    type: 'GET',
    url: resources[0],
    dataType: 'script',
    cache: true, // prevents cache buster
  })
    .done(callback)
    .fail(errorCallback);
};

export { ensureJqueryObject, loadResources };
