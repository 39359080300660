import DataLayer from 'app/modules/datalayer';

/**
 * SearchOverlayCollection is the collection used for managing content media models
 * in the SearchOverlay.js module.
 */
export default Backbone.Collection.extend({
  // Ajax endpoint for search autosuggest links
  endpoint: 'ajax/searchautosuggest',

  /**
   * formSearchQueryEndpoint returns a search query endpoint url
   *
   * @param  {string} query  Search query
   *
   * @return {string}        Endpoint url for this search query
   */
  formSearchQueryEndpoint: function formSearchQueryEndpoint(query) {
    let url = '/search/?q=' + query;

    return query ? this.formLocalizedUrl(url) : '';
  },

  /**
   * formLocalizedUrl returns a url before checking to see if there
   * is a locale url path in the data layer first.
   *
   * @param  {string} url
   *
   * @return {string}
   */
  formLocalizedUrl: function formLocalizedUrl(url) {
    let locale = DataLayer.getValue('locale.urlPath');

    return locale ? '/' + locale + url : url;
  },

  /**
   * parse function uses the response from successful fetch from server,
   * returns an array of model attributes that will be added to the collection
   *
   * @param  {array} response    Array of objects from a successful fetch
   *
   * @return {array}             Array of objects that will be added as GenericModel attributes
   */
  parse: function parse(response) {
    return response.map(
      function map(item) {
        return {
          data: {
            term: item.input ? item.input.toLowerCase() : '', // user input
            label: item.tag ? item.tag.toLowerCase() : '', // tag
            searchLink: this.formSearchQueryEndpoint(item.tag ? item.tag.toLowerCase() : ''), // tag link
            type: item.tagType ? item.tagType.toLowerCase() : '', // tag type
          },
        };
      }.bind(this),
    );
  },
});
