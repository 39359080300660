import { create } from 'app/modules/inview';

/**
 * Additional functionality for paywall banners.
 *
 * TODO: Remove Piano after all sites have switched to JAM
 */
export default class PaywallBanner {
  /**
   * Set up intersection observer.
   *
   * @param {NodeList} banners Paywall banner container element(s)
   */
  constructor(banners) {
    this.banners = banners;

    const observer = create([this.dockBanners.bind(this), this.undockBanners.bind(this)]);
    observer.observe(document.querySelector('.footer'));
  }

  /**
   * Return an instance if a banner is present in the DOM.
   */
  static setup(selector = '.piano-container-wrapper, .journey-container-wrapper') {
    const banners = document.querySelectorAll(selector);

    return banners.length ? new PaywallBanner(banners) : null;
  }

  /**
   * Dock all banners above the footer
   */
  dockBanners() {
    [...this.banners].map(PaywallBanner.dockBanner);
  }

  /**
   * Undock all banners
   */
  undockBanners() {
    [...this.banners].map(PaywallBanner.undockBanner);
  }

  /**
   * Statically position banner above footer
   *
   * @param {Element} banner Paywall banner container
   */
  static dockBanner(banner) {
    const bannerChild = banner.firstElementChild;
    banner.style.height = '';
    bannerChild.classList.add('docked');
  }

  /**
   * Fix banner to the bottom of the screen
   *
   * @param {Element} banner Paywall banner container
   */
  static undockBanner(banner) {
    const bannerChild = banner.firstElementChild;
    banner.style.height = bannerChild.offsetHeight + 'px';
    bannerChild.classList.remove('docked');
  }
}
