/**
 * DataLayer module handles functionality relating to retrieving data
 * from the "data layer" object that contains various page data used
 * in ads and analytics scripts.
 *
 * It also includes some handy modules to help reduce code duplication
 * in Ensighten deployments.
 *
 * The data layer exists as an "application/json" script tag on the
 * page, Ex:
 *
 * <script id="data-layer" type="application/json">
 * {
 *     "canonicalUrl" : "//foo.com/foo",
 *     "content" : {
 *         "title" : "Foo"
 *     }
 * }
 * </script>
 *
 * The DataLayer module parses and stores the contents of the script
 * tag on when the document is ready.
 *
 *
 * Usage:
 *
 * getValue - Retrieves values from the data layer. Ex:
 *
 * // get a value
 * DataLayer.getValue( "canonicalUrl" );
 *
 * // get a nested value using . notation
 * DataLayer.getValue( "content.title" );
 *
 * // provide defaults for values that don't exist
 * DataLayer.getValue( "nope", "some fallback" );
 *
 * // returns false by default on nonexistant keys
 * if ( DataLayer.getValue( "content" ) ) {
 *     // this is a content page!
 * }
 */
const DataLayer = {
  // id of script tag containing data layer
  scriptTagId: 'data-layer',

  // cache for the data layer JSON
  dataLayer: {},

  // attempt to retrieve and parse JSON from the
  // data layer script tag
  scrapeDataLayer: function scrapeDataLayer() {
    // default to an empty object
    let json = {};
    // get the data layer script element
    let script = document.getElementById(this.scriptTagId);

    // if there was a valid script element
    if (script) {
      try {
        json = JSON.parse(script.innerHTML);
      } catch (err) {
        // log a warning if there was an error parsing the json
        // eslint-disable-next-line no-console
        console.warn('DataLayer: Unable to parse json at script tag id %s.', this.scriptTagId, err);
      }
    }

    // set the data layer
    this.dataLayer = json;

    // return the result
    return json;
  },

  // returns the whole data layer object
  getDataLayer: function getDataLayer() {
    return this.dataLayer;
  },

  // attempts to retrieve a value from
  // the data layer
  getValue: function getValue(key, fallback) {
    return this.getObjectValue(key, this.getDataLayer(), fallback);
  },

  /**
   * Attempts to retrieve a value from a source object. If
   * the value couldn't be found, returns false or a specified
   * fallback value.
   *
   * @param  {string} key      Key of value to be retrieved. Can also be
   *                           specified using dot notation to retrieve
   *                           nested values. Ex: "content.title"
   * @param  {object} source   Source object.
   * @param  {mixed}  fallback Value to be returned if key could not be
   *                           retrieved.
   * @return {mixed}           Value of the key, false, or specified fallback
   */
  getObjectValue: function getObjectValue(key, source, fallback) {
    // get the key parts
    let parts = key.split('.');

    // shift the first key off the front
    key = parts.shift();

    // use provided default or false
    fallback = typeof fallback === 'undefined' ? false : fallback;

    // if the source doesn't contain the key, return the fallback
    if (!Object.prototype.hasOwnProperty.call(source, key)) {
      return fallback;
    }

    // if there are left over key parts, recurse. otherwise return the value
    return parts.length ? this.getObjectValue(parts.join('.'), source[key], fallback) : source[key];
  },

  // domLoad is used to call a callback upon either the DOM already being loaded,
  // or upon load
  domLoad: function domLoad(callback) {
    // Check callback
    callback = this.errorCheckCallback(callback);

    return this.getDocumentReadyState() !== 'loading'
      ? callback()
      : document.addEventListener('DOMContentLoaded', callback);
  },

  // windowLoad is used to call a callback upon either the DOM already being loaded,
  // or upon window load
  windowLoad: function windowLoad(callback) {
    // Check callback
    callback = this.errorCheckCallback(callback);

    if (this.getDocumentReadyState() === 'complete') {
      callback();
    } else {
      window.onload = callback;
    }
  },

  // returns document.readyState
  getDocumentReadyState: function getDocumentReadyState() {
    return document.readyState;
  },

  // errorCheckCallback checks that a callback function is valid,
  // otherwise defines an empty function and returns it
  errorCheckCallback: function errorCheckCallback(callback) {
    let noop = function noop() {};
    // Check for valid callback, add default if needed
    return callback === undefined || typeof callback !== 'function' ? noop : callback;
  },
};

// scrape the data layer when the dom is ready
DataLayer.domLoad(DataLayer.scrapeDataLayer.bind(DataLayer));

export default DataLayer;
