import Logger from 'app/shared/logger';
import VideoPlayer from 'app/modules/videoplayer';
import { getValue } from 'app/shared/utils';

export default VideoPlayer.extend({
  playerType: 'youtube',

  logger: new Logger('youtubeplayer'),

  /**
   * Initialize the Youtube Player
   *
   */
  initialize: function initialize() {
    // Return early if there are no matching elements
    if (!this.$el.length) {
      return;
    }

    this.setVariables();

    // If the API is loaded, onYouTubeIframeAPIReady has already fired
    // Set the player and return early
    if (this.YT.loaded) {
      this.setPlayer();
      return;
    }

    this.setListeners();
  },

  /**
   * Sets variables
   */
  setVariables: function setVariables() {
    this.YT = getValue('YT', window, {});
    this.channel = Backbone.Radio.channel(this.playerType);

    this.playerEvents = {
      onReady: this.initializePlayer.bind(this),
      onStateChange: this.onPlayerStateChange.bind(this),
    };

    this.playerActions = {
      play: 'playVideo',
      pause: 'pauseVideo',
    };
  },

  /**
   * Sets listeners
   */
  setListeners: function setListeners() {
    // Set a ready listener on the radio channel
    // for every player setup before the API was ready
    this.channel.on('ready', this.setPlayer.bind(this));

    // If onYouTubeIframeAPIReady is not a function
    // This player is the first to be setup without an API
    // Set up the callback to trigger the event on the channel
    // which will propagate for every instance listening on it
    if (!_.isFunction(window.onYouTubeIframeAPIReady)) {
      this.logger.log('onYouTubeIframeAPIReady has been set');
      // Trigger event on channel for all players
      window.onYouTubeIframeAPIReady = this.channel.trigger.bind(this.channel, 'ready');
    }
  },

  // Begin gettters/setters

  setPlayer: function setPlayer() {
    // Attempts to get the Player instance from the YT object
    // Otherwise it fallbacks to dummy function
    let Player = getValue('Player', this.YT, $.noop);

    // Set player instance to public property
    this.playerInstance = Player;

    // If the player has already been created, return the player instance
    if (this.isReady()) {
      return this.player;
    }

    this.supportedPlayerStates = {
      play: [getValue('PlayerState.PLAYING', this.YT)],
      pause: [getValue('PlayerState.PAUSED', this.YT)],
      end: [getValue('PlayerState.ENDED', this.YT)],
    };

    // This instantiates a new YT.Player instance
    // passing the node and player events
    this.player = new Player(this.$el.get(0), {
      events: this.playerEvents,
    });

    return this.player;
  },

  /**
   * Gets the player state
   *
   * @return {int} Youtube player maps integers to every event
   */
  getState: function getState() {
    return this.player.getPlayerState();
  },

  // End gettters/setters

  // Begin utilities

  /**
   * Checks if this instance is ready
   *
   * @return {bool} True, if initialized correctly
   */
  isReady: function isReady() {
    return this.player instanceof this.playerInstance;
  },

  // End utilities

  // Begin event handlers

  /**
   * Proxy to handleStateChange, filtering the event from the raw data
   *
   * @param {object} event Player event data
   */
  onPlayerStateChange: function onPlayerStateChange(event) {
    this.handleStateChange(getValue('data', event, 0));
  },

  // End event handlers

  // Begin player action handlers

  /**
   * Prevents a player from pausing after going out of view
   */
  pausePlayer: function pausePlayer() {},

  // End player action handlers
});
