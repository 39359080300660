/* global FB */
import { errorCheckCallback } from 'app/shared/utils';
import { loadResources } from '../shared/utilslegacy';

export default Backbone.View.extend({
  // Require JS path for Facebook's SDK
  requireJSPath: __FACEBOOK__,

  // Facebook app id loaded from meta tag
  appId: null,

  initialize: function initialize() {
    this.setVariables();
  },

  setVariables: function setVariables() {
    // Get the app id from the fb:app_id meta tag
    this.appId = $("meta[property='fb:app_id']").attr('content');
  },

  loadFacebookSDK: function loadFacebookSDK(callback) {
    let fn;
    // assure callback is a function
    callback = errorCheckCallback(callback);

    fn = this.handleSDKLoaded.bind(this, callback);

    // Load the facebook SDK
    loadResources([this.requireJSPath], fn);
  },

  handleSDKLoaded: function handleSDKLoaded(callback) {
    // Initialize the facebook SDK
    FB.init({
      appId: this.appId,
      version: 'v2.10',
      xfbml: true,
    });

    // pass the FB sdk to the callback
    callback(FB);
  },
});
