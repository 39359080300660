import Debounce from 'app/shared/debounce';
import FeedModules from 'app/modules/feedmodules';
import GlobalScript from 'app/shared/globalscript';
import TrackingPixels from 'app/modules/trackingpixels';
import VideoLoader from 'app/modules/videoloader';
import { loadApp } from 'app/shared/utils';

const App = Backbone.View.extend({
  el: $('body'),

  initialize: function initialize() {
    this.setVariables();
  },

  // Begin setter functions
  setVariables: function setVariables() {
    // Set up the modules in the global script
    this.globalScript = new GlobalScript();

    // Set up the feed modules
    this.feedModules = new FeedModules();

    // Set up the video loader to support TBF big story video
    this.videoLoader = new VideoLoader();

    // Set up tracking pixel support
    this.trackingPixels = new TrackingPixels();

    Debounce.on('scroll', this.handleScroll.bind(this), 'Feed');
  },
  // End setter functions

  handleScroll: function handleScroll() {
    Debounce.off('scroll', 'Feed');

    // set up "Load More" button
    import('app/modules/loadmore').then((loadmore) => {
      const { default: LoadMore } = loadmore;
      const feedContainer = document.querySelector('.feed');
      this.loadMore = new LoadMore(feedContainer);
    });
  },
});

// Load the app
export default loadApp(App);
