export default {
  // begin loader functions
  /**
   * Loads <img>, <video> or other single elements that have data-src
   * and/or data-srcset attributes.
   *
   * @param {object}   el       DOM Node of the asset to load.
   * @param {function} callback Function to run once element has loaded.
   *
   * Example:
   * <img data-src="/foo.jpg" data-srcset="/foo-hires.jpg 2x" />
   * <video data-src="/foo.mp4" />
   */
  default: function def(el, callback) {
    el.onload = callback;

    this.swapDataAttributes(el);
  },

  /**
   * Loads <picture> elements that contain <source> or <img> nodes
   * that have data-src and/or data-srcset attributes.
   *
   * @param {object}   el DOM Node of the asset to load.
   * @param {function} callback Function to run once element has loaded.
   *
   * Example:
   * <picture>
   *     <source data-srcset="hires.jpg" media="(min-resolution: 192dpi)" />
   *     <img data-src="default.jpg" />
   * </picture>
   */
  picture: function picture(el, callback) {
    // loop over the <picture> element's children
    [].forEach.call(
      el.children,
      function forEach(node) {
        // when we hit an <img> node, attach
        // the callback to its onload event
        if (node.nodeName === 'IMG') {
          node.onload = callback;
        }

        // attempt to swap data attributes on both <source> and <img> elements
        this.swapDataAttributes(node);
      }.bind(this),
    );
  },

  /**
   * Announces the element passed in on the channel.
   * TODO - figure out how to get to the background images so we can preload
   *        them before announcing the element has loaded.
   *
   * @param {object} el DOM node
   * @param {function} callback Function to run once element has loaded.
   *
   * NOTE: To make the best use of this, it is recommended to add the background
   * image behind an active class which gets added in a callback to this channel
   * announce.
   */
  background: function background(el, callback) {
    callback();
  },
  // end loader functions

  // begin utility functions
  /**
   * Swaps values of data-src/srcset attributes with src/srcset attributes.
   *
   * @param {object} el DOM node to switch attributes on.
   */
  swapDataAttributes: function swapDataAttributes(el) {
    ['src', 'srcset'].forEach(function forEach(attr) {
      let dataAttr = 'data-' + attr;

      if (el.hasAttribute(dataAttr)) {
        el.setAttribute(attr, el.getAttribute(dataAttr));
      }
    });
  },
  // end utility functions
};
