import { preventEventActions, addListener } from 'app/shared/utils';

/**
 * SidePanel defines the functionality for the site's side panel navigation menu.
 */
class SidePanel {
  constructor(selector = '.sidepanel, .new-sidepanel') {
    this.selector = selector;
    this.sidepanel = document.querySelector(this.selector);

    if (!this.sidepanel) return;

    this.sidepanelItems = this.sidepanel.querySelectorAll(
      '.sidepanel-item.has-children, .new-sidepanel .has-children',
    );
    this.sidepanelSubmenuItems = this.sidepanel.querySelectorAll(
      '.sidepanel-submenu-item, .sidepanel-new-submenu-item, .sidepanel-location-choice-submenu-item, .new-sidepanel-submenu-item',
    );
    this.activeClass = 'active';
    this.noScrollClass = 'no-scroll';

    this.isNewNav = this.sidepanel.classList && this.sidepanel.classList.contains('new-sidepanel');

    this.sidepanelItems.forEach((sidepanelItem) => {
      sidepanelItem.addEventListener('click', this.toggleSubmenu.bind(this));
      sidepanelItem.addEventListener('keydown', this.sidepanelItemKeydown.bind(this));
    });

    this.sidepanelSubmenuItems.forEach((sidepanelSubmenuItem) => {
      sidepanelSubmenuItem.addEventListener('click', this.constructor.cancelToggle);
    });

    if (this.isNewNav) {
      addListener(window, 'hashchange', this.toggleNoScroll.bind(this));
      this.toggleNoScroll();
    }
  }

  /**
   * If space or enter is pressed on the side panel menu that has children
   * then the toggleSubmenu function is called to either open or close the submenu
   *
   * @param {KeyboardEvent} e Event object
   */
  sidepanelItemKeydown(e) {
    const { keyCode } = e;

    // keyCode 32 === Space
    // keyCode 13 === Enter
    if (keyCode === 13 || keyCode === 32) {
      this.toggleSubmenu(e);
    }
  }

  toggleSubmenu(e) {
    const target = e.currentTarget;
    // if we found current sidepanel item has submenus, toggle it
    if (
      target.querySelector(
        '.sidepanel-submenu, .sidepanel-new-submenu, .sidepanel-location-choice-submenu, .new-sidepanel-submenu',
      )
    ) {
      // if an a tag was clicked in the new nav follow the link as normal
      if (this.isNewNav && e.target.matches('a:not([href="#"])')) {
        return;
      }
      // prevent default and stop propagation
      preventEventActions(e);

      if (target.classList.contains(this.activeClass)) {
        // remove activeClass on current sidepanel item
        target.classList.remove(this.activeClass);
        // set the aria-expanded attribute to false if collapsing the submenu
        target.setAttribute('aria-expanded', false);
      } else {
        // remove activeClass on sibling sidepanel items
        this.sidepanel
          .querySelectorAll(`.${this.activeClass}`)
          .forEach(item => item.classList.remove(this.activeClass));

        // add activeClass on current sidepanel item
        target.classList.add(this.activeClass);
        // set the aria-expanded attribute to true  if expanding the submenu
        target.setAttribute('aria-expanded', true);
      }
    }
  }

  /**
   * Toggle the no-scroll class on the body tag when the sidepanel is open
   */
  toggleNoScroll() {
    document.body.classList.toggle(this.noScrollClass, window.location.hash === '#sidepanel');
  }

  static cancelToggle(e) {
    // cancel bubble on regular submenu items
    e.stopPropagation();
  }
}

export default SidePanel;
