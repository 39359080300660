import Debounce from 'app/shared/debounce';
import Logger from 'app/shared/logger';

/**
 * MediaQueryEvent broadcasts "breakpoint" events when SASS breakpoint thresholds
 * are crossed during resize, and on load. This View uses the "font-family" attribute
 * appended to the head font-family to determine breakpoints.
 * See _breakpoint-events.scss in the mp-bower-assets/scss directory
 */
let MediaQuery = Backbone.View.extend({
  el: 'head',
  breakpoints: {},
  currentBreakpoint: {
    index: -1, // allows setting the initial breakpoint
  },
  logger: new Logger('MediaQueryEvent'),
  mediaQueryChannel: Backbone.Radio.channel('mediaquery'),

  /**
   * Process the breakpoints and set variables and resize handler
   */
  initialize: function initialize() {
    // TODO: Remove processing and load object directly in bundle
    let breakpoints = this.processBreakpoints();

    this.breakpoints = breakpoints;
    this.bpEntries = _.pairs(breakpoints);
    this.bpIndexLength = this.bpEntries.length - 1;

    this.checkBreakpointChange(window.innerWidth);
    this.setRadioEvents();
    Debounce.on('resize', this.checkBreakpointChange.bind(this), 'mediaquery');
  },

  /**
   * Sets the radio events for helper methods
   */
  setRadioEvents: function setRadioEvents() {
    this.mediaQueryChannel
      .reply('instance', this) // ! Needed for testing only
      .reply('current', this.getCurrentBreakpoint.bind(this))
      .reply('is', this.is.bind(this))
      .reply('isGt', this.isGt.bind(this))
      .reply('isGte', this.isGte.bind(this))
      .reply('isLt', this.isLt.bind(this))
      .reply('isLte', this.isLte.bind(this));
  },

  /**
   * Read the stringified json from the DOM and parse it
   */
  processBreakpoints: function processBreakpoints() {
    let cssValue = this.$el.css('font-family');
    let regex = new RegExp('({.*})');
    let matches = cssValue.match(regex);
    let breakpoints;
    let result;

    // Exit early if no object was found
    if (!matches) {
      this.logger.error('Breakpoints object was not found in head font-family', {
        value: cssValue,
      });
      return {};
    }

    // Strip out escaped slashes
    result = matches[0].replace(/(\\)/g, '');

    // Parse breakpoints map into json
    try {
      breakpoints = JSON.parse(result);
    } catch (err) {
      // This should be fun
      this.logger.error('Breakpoints Parse Error', {
        value: matches[0],
      });
      return {};
    }

    return breakpoints;
  },

  /**
   * Resize handler to update the current breakpoint
   */
  checkBreakpointChange: function checkBreakpointChange(width) {
    let newIndex = -1;
    let next;

    // If the current width is lower and current bp is not the lowest bound
    if (this.currentBreakpoint.index > 0 && width < this.currentBreakpoint.value) {
      newIndex = this.currentBreakpoint.index - 1;
      // loop backwards to confirm the match
      while (newIndex > 0 && width < this.bpEntries[newIndex][1]) {
        newIndex -= 1;
      }
    } else if (this.currentBreakpoint.index < this.bpIndexLength) {
      next = this.currentBreakpoint.index + 1;
      // If the current width is greater than the min width of next breakpoint
      if (width >= this.bpEntries[next][1]) {
        newIndex = next;
        // loop forward to confirm the match
        while (newIndex < this.bpIndexLength && width >= this.bpEntries[newIndex + 1][1]) {
          newIndex += 1;
        }
      }
    }

    if (newIndex >= 0) {
      this.setCurrentBreakpoint(newIndex);
    }
  },

  /**
   * Sets the fields of the current breakpoint and trigger events on the channel
   */
  setCurrentBreakpoint: function setCurrentBreakpoint(index) {
    this.currentBreakpoint = {
      label: this.bpEntries[index][0],
      value: this.bpEntries[index][1],
      index,
    };

    this.mediaQueryChannel.trigger('change', this.currentBreakpoint.label);
  },

  /* helper methods 👇 */
  getCurrentBreakpoint: function getCurrentBreakpoint() {
    return this.currentBreakpoint.label || '';
  },

  is: function is(label) {
    return this.currentBreakpoint.label === label;
  },

  isGt: function isGt(label) {
    return this.currentBreakpoint.value > this.breakpoints[label];
  },

  isGte: function isGte(label) {
    return this.currentBreakpoint.value >= this.breakpoints[label];
  },

  isLt: function isLt(label) {
    return this.currentBreakpoint.value < this.breakpoints[label];
  },

  isLte: function isLte(label) {
    return this.currentBreakpoint.value <= this.breakpoints[label];
  },
});

// initialize the module once
// to prevent multiple DOM manipulations
// and event handlers
let MQ = new MediaQuery();

// return the radio channel
export default MQ.mediaQueryChannel;
