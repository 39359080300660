import Logger from 'app/shared/logger';
import { getValue } from 'app/shared/utils';

/**
 * YoutubePlaylist defines the functionality for youtube playlist embeds
 * */
export default Backbone.View.extend({
  el: '.embed-youtube-playlist',

  endpoint: '/ajax/playlist/',

  events: {
    'click .playlist-thumb': 'onClick',
    'click .playlist-arrow': 'onArrowClick',
  },

  initialize: function initialize() {
    // Return early if there are no matching elements
    if (!this.$el.length) {
      return;
    }

    // Set variables
    this.setVariables();

    // Initialize player
    this.initializePlayer();
  },

  /**
   * Sets variables
   */
  setVariables: function setVariables() {
    // url language prefix
    let prefix = getValue('URL_LANGUAGE_PREFIX', window, false);

    // if there's an url language prefix, prepend it to the endpoint
    if (prefix) {
      this.endpoint = '/' + prefix + this.endpoint;
    }

    // Set defaults
    this.overrideDefaults({
      // class applied to container when enabled
      enabledClass: 'enabled',
      // media loader instance
      mediaLoader: null,
      // class applied to selected thumb
      selectedClass: 'selected',
      // thumbnail class
      thumbClass: '.playlist-thumb',
      // class applied to thumbnails containers
      thumbnailsContainerClass: '.playlist-thumbnails',
      // thumbnails wrapper
      thumbnailsWrapperClass: '.playlist-thumbs--wrapper',
      // thumbnails inner wrap
      thumbsInnerWrapClass: '.playlist-thumbs--inner-wrap',
      // thumbnail underscore template selector
      thumbnailTemplateSelector: '#playlist-thumbnail',
    });

    // Set local YT object
    this.YT = getValue('YT', window, {});

    // Initialize Logger
    this.logger = new Logger('YoutubePlaylist');

    // flags
    this.scrolling = false;
    this.loading = this.scrolling;

    // Stores the YT player instance
    this.player = {};

    // Player event callbacks
    this.playerEvents = {
      onReady: this.onReady.bind(this),
      onStateChange: this.onStateChange.bind(this),
    };

    // Thumbnails
    this.$thumbs = null;

    // Thumbnails wrapper
    this.$thumbnailsWrapper = this.$el.find(this.thumbnailsWrapperClass);

    // Thumbnails inner wrap
    this.$thumbnailsInnerWrap = this.$el.find(this.thumbsInnerWrapClass);

    // Get underscore template for thumbnails
    this.thumbnailTemplate = _.template($(this.thumbnailTemplateSelector).html());
  },

  /**
   * Initializes a YouTube player instance
   */
  initializePlayer: function initializePlayer() {
    let $iframe = this.$el.find('iframe');
    let playlistId = this.$el.data('playlistId');

    if (this.YT.Player && playlistId && _.isEmpty(this.player)) {
      // Initialize YT instance with iframe's id
      this.player = new this.YT.Player($iframe.attr('id'), {
        events: this.playerEvents,
      });
    }
  },

  /**
   * Calls the playlist method to obtain thumbnails and titles of videos
   *
   * @param  {object}    options     Request parameters
   */
  getThumbnails: function getThumbnails(options) {
    // type check options
    options = _.isObject(options) ? options : {};
    // set flag on
    this.loading = true;

    // Call endpoint
    $.ajax(this.endpoint, {
      type: 'GET',
      data: $.extend(
        {
          id: this.player.getPlaylistId(),
        },
        options,
      ),
      context: this,
      dataType: 'json',
    })
      .always(this.resetFlag.bind(this))
      .done(this.renderThumbnails.bind(this))
      .fail(this.requestFailed.bind(this));
  },

  /**
   * Resets loading flag
   */
  resetFlag: function resetFlag() {
    this.loading = false;
  },

  /**
   * Renders the thumbnails and appends them to the rail
   *
   * @param  {object} response       Data response
   */
  renderThumbnails: function renderThumbnails(response) {
    let data = getValue('data', response);

    // If there's no data, return
    if (!data) {
      this.logger.log('Thumbnails failed to render with:', data, this.player);
      return;
    }

    // Set current index
    this.player.currentIndex = this.player.getPlaylistIndex();
    // Set next page token
    this.player.nextPageToken = getValue('nextPageToken', data);
    // Set or add items
    this.player.items = this.player.items ? this.player.items.concat(data.items) : data.items;

    // If current index is not in this page, recursively call the endpoint
    if (this.player.currentIndex > this.player.items.length - 1) {
      this.loadNextPage();
      return;
    }

    // Add thumbnails
    this.addThumbnails(this.player.items);

    // If page was not loaded as result of scrolling
    // Select thumb, snap for initial load
    if (!this.scrolling) {
      this.selectThumb(this.player.currentIndex, !this.$el.hasClass(this.enabledClass));
    }

    // Reset scrolling flag
    this.scrolling = false;

    // Fade in thumbnails if hidden
    this.$el.addClass(this.enabledClass);

    // If there are no more pages, unbind scroll event
    if (!this.player.nextPageToken) {
      this.$thumbnailsWrapper.off('scroll');
      return;
    }

    // Bind scroll event
    this.$thumbnailsWrapper.on('scroll', _.debounce(this.handleScroll.bind(this), 250));
  },

  /**
   * Appends thumbnails to rail
   *
   * @param {array} items Array of thumbnails
   */
  addThumbnails: function addThumbnails(items) {
    let thumbnails = '';

    // If items is not an array, return
    if (!_.isArray(items)) {
      return;
    }

    // If thumbnails have been added, trim response after the last item added
    if (this.$thumbs) {
      items = items.slice(this.$thumbs.last().index() + 1, this.player.items.length);
    }

    // Render thumbnails
    items.forEach(
      function forEach(item) {
        thumbnails += this.thumbnailTemplate(item);
      }.bind(this),
    );

    // Append to container
    this.$thumbnailsInnerWrap.append(thumbnails);

    // Update thumbnails collection
    this.$thumbs = this.$el.find(this.thumbClass);
  },

  /**
   * Loads next page, if player has a nextPageToken
   */
  loadNextPage: function loadNextPage() {
    if (this.player.nextPageToken) {
      this.getThumbnails({
        pageToken: this.player.nextPageToken,
      });
    }
  },

  /**
   * Logs the arguments of the failed response
   */
  requestFailed: function requestFailed() {
    // Log failure arguments
    this.logger.error('Request failed', arguments);
  },

  /**
   * Callback when player has been initialized, sets current index of playlist
   */
  onReady: function onReady() {
    // Call endpoint to get thumbnails/titles and render them
    this.getThumbnails();
  },

  /**
   * Fires when a player embed changes its state ( playing, pause, etc )
   */
  onStateChange: function onStateChange() {
    let playlistIndex = this.player.getPlaylistIndex();
    // If index has changed, select the corresponding thumbnail
    if (this.player.items && playlistIndex !== this.player.currentIndex) {
      // If new index is not in this page, load next page
      if (!this.loading && playlistIndex > this.player.items.length - 1) {
        this.loadNextPage();
        return;
      }
      // Otherwise select thumb
      this.selectThumb(playlistIndex);
    }
  },

  /**
   * Plays a specific video of the playlist
   *
   * @param  {object} event Click event
   */
  onClick: function onClick(event) {
    let $target = $(event.currentTarget);

    if (!$target.hasClass(this.selectedClass)) {
      // Select thumbnail
      this.selectThumb($target.index());
      // Play video
      this.player.playVideoAt(this.player.currentIndex);
    }
  },

  /**
   * Arrow click handler, controls the thumbnail rail
   *
   * @param  {object} event Click event
   */
  onArrowClick: function onArrowClick(event) {
    let $target = $(event.currentTarget);
    let direction = $target.data('direction');
    let wrapWidth = this.$thumbnailsWrapper.width();
    let value = direction === 'left' ? -Math.abs(wrapWidth) : wrapWidth;
    let result = this.$thumbnailsWrapper.scrollLeft() + value;

    // If rail has not been populated/shown return
    if (!this.$el.hasClass(this.enabledClass)) {
      return;
    }

    // Animate thumbnail rail
    this.$thumbnailsWrapper.animate(
      {
        scrollLeft: result,
      },
      300,
    );

    // Check scroll position
    if (this.player.nextPageToken) {
      this.checkScroll(result);
    }
  },

  /**
   * Event handler for scrolling event
   */
  handleScroll: function handleScroll() {
    // If a next page it's not loading, check the scroll value
    if (!this.loading) {
      this.checkScroll(this.$thumbnailsWrapper.scrollLeft());
    }
  },

  /**
   * Checks if scroll is near the end, and loads the next page if needed
   *
   * @param  {integer} value Scroll left value to check
   */
  checkScroll: function checkScroll(value) {
    let wrapWidth = this.$thumbnailsWrapper.width();
    let wrapOffset = this.$thumbnailsInnerWrap.width() - wrapWidth;
    let result = value >= wrapOffset * 2;

    // If closer to the end, load next page
    if (result && !this.loading) {
      this.scrolling = true;
      this.loadNextPage();
    }
  },

  /**
   * Selects thumbnail in rail and scrolls to it
   *
   * @param  {integer} index  Current index
   * @param  {boolean} snap   Disables animation, snaps to selected thumb
   */
  selectThumb: function selectThumb(index, snap) {
    let thumbWidth;
    let offset;
    let offsetValue;
    // Set current index
    if (_.isNumber(index)) {
      this.player.currentIndex = index;
    }
    // Get the individual space of a single thumbnail to account for margins
    thumbWidth = this.$thumbnailsInnerWrap.width() / this.$thumbnailsInnerWrap.children().length;
    offsetValue = thumbWidth * this.player.currentIndex;
    offsetValue -= this.$thumbnailsWrapper.width();

    // get the value to align the rail at the left edge
    // and reduce the wrapper width to center it
    offset = offsetValue / 3;

    // Deselect all thumbs and select current index
    this.$thumbs
      .removeClass(this.selectedClass)
      .eq(this.player.currentIndex)
      .addClass(this.selectedClass);

    // Scroll to thumb
    this.$thumbnailsWrapper.animate(
      {
        scrollLeft: offset,
      },
      snap ? 0 : 300,
    );
  },
});
