import Mylo from '@media-platforms/mylo-sdk-javascript';
import { addListener, getCookieByName, getValue, getQueryParamObj } from 'app/shared/utils';
import Logger from 'app/shared/logger';

const fetchInit = {
  credentials: 'same-origin',
};

const windowLocationReload = () => window.location.reload(true);
const windowLocationAssign = url => window.location.assign(url);
const spotimEvents = [
  'spot-im-login-start',
  'spot-im-clicked-like-thumbs-up',
  'spot-im-clicked-like-thumbs-down',
  'spot-im-clicked-flag',
];

/**
 * ciamauth defines the functionality for interacting with ciam-auth identity
 */
export default class CiamAuth {
  constructor() {
    // Initialize variables
    this.initVariables();
    this.pianoAddHandlers();

    this.mylo = new Mylo({
      clientId: CiamAuth.getMyloClientId(),
      myloApiBaseUrl: this.ciamUiUrl,
      myloUiBaseUrl: this.ciamUiUrl,
      redirectUri: `${window.location.origin}/auth/login`,
      pkce: false,
    });

    if (this.shouldCheckAccess()) {
      this.checkTokenAccess();
    } else {
      this.updateNavButton(false);
    }

    // add spotim event handlers if spotim sso is enabled
    if (CiamAuth.isSpotIMSSOEnabled()) {
      this.spotimAddHandlers();
    }
  }

  /**
   * This is written by spotIM for SSO and would make user log
   * in spotim. spotIM gives the SSO session a unique identifier called codeA.
   * CodeA is then passed to the backend which returns codeB. codeB is then
   * send as parameter to the spotIM callback function completeSSOCallback.
   * @param {string} codeA The codeA you got through the API call
   *                 from the FrontEnd. codeA is provided by spotIM
   * @param {function} completeSSOCallback callback function provided by spotIM
   *                   to return codeB
   */
  async spotimLoginUser(codeA, completeSSOCallback) {
    const { username, preferredUsername } = getValue('HRSTobj.mylo', this, {});

    // call the backend only if the username and preferredusername are available
    if (username && preferredUsername) {
      const email = this.myloEmail;
      const response = await fetch(
        `${this.spotimLoginEndpoint}?code_a=${codeA}&primary_key=${username}&user_name=${preferredUsername}&email=${email}`,
      );
      const codeB = await response.text();

      // complete the log in by sending the codeB back to spotim
      completeSSOCallback(codeB);
    }
  }

  /**
   * This method initiate the spotim login process and returns the user
   * data if it is a successful login
   */
  spotimStartSSO() {
    const { username, preferredUsername } = getValue('HRSTobj.mylo', this, {});
    // if the user is not logged into mylo, log the user out from spotim also
    if (username && preferredUsername) {
      window.SPOTIM.startSSO(this.spotimLoginUser.bind(this))
        .then((userData) => {
          // userData contains information about the logged in user
          this.logger.log('userData: ', userData);
        })
        .catch((reason) => {
          // reason contains error details
          this.logger.warn('spotimStartSSO error reason: ', reason);
          this.spotimAddHandlers();
        });
    } else {
      window.SPOTIM.logout();
    }
  }

  /**
   * Setup a new CiamAuth object if the SSO flag is true
   */
  static setup() {
    return getValue(CiamAuth.getSsoFlagName(), window) ? new CiamAuth() : null;
  }

  /**
   * Name of the SSO flag
   */
  static getSsoFlagName() {
    return 'HRST.site.sso';
  }

  /**
   * Name of the enable_commenting flag
   */
  static getEnableCommentingToggleName() {
    return 'HRST.article.enable_commenting';
  }

  /**
   * Name of the SpotIM commenting flag
   */
  static getCommentingFlagName() {
    return 'HRST.site.commenting';
  }

  /**
   * Name of the new-nav flag
   */
  static getNewNavFlagName() {
    return 'HRST.site.new-nav';
  }

  /**
   * Returns the graph path to access the mylo client id under `window`.
   */
  static getMyloClientIdPath() {
    return 'CURRENT_SITE.metadata.ciam.clientId';
  }

  /**
   * Returns the path to access the spotIM ssotoken under `window`.
   */
  static getSpotIMSSOTokenPath() {
    return 'CURRENT_SITE.metadata.spotim.ssotoken';
  }

  /**
   * Returns the path to access the spotIM id under `window`.
   */
  static getSpotIMIdPath() {
    return 'CURRENT_SITE.metadata.spotim.id';
  }

  /**
   * Returns the object graph path to access the ciam ui url under `window`.
   */
  static getCiamUiUrlPath() {
    return 'CIAM_URL';
  }

  /**
   * Check if new nav is enabled
   */
  static isNewNav() {
    return getValue(CiamAuth.getNewNavFlagName(), window);
  }

  /**
   * Check if enabled_commenting is true
   */
  static isEnableCommenting() {
    return !!getValue(CiamAuth.getEnableCommentingToggleName(), window);
  }

  /**
   * Check if spotim_commenting feature flag is true
   */
  static isCommentingAllowed() {
    return getValue(CiamAuth.getCommentingFlagName(), window);
  }

  /**
   * Returns SpotIM SSO Token
   */
  static getSpotIMSSOToken() {
    return getValue(CiamAuth.getSpotIMSSOTokenPath(), window);
  }

  /**
   * Returns SpotIM Id
   */
  static getSpotIMId() {
    return getValue(CiamAuth.getSpotIMIdPath(), window);
  }

  /**
   * Returns the ciam ui url
   */
  static getCiamUiUrl() {
    return getValue(CiamAuth.getCiamUiUrlPath(), window, '').replace(/\/$/, '');
  }

  /**
   * Returns the Mylo client id found at `CURRENT_SITE.metadata.ciam.clientId` or
   * an empty string.
   */
  static getMyloClientId() {
    return getValue(CiamAuth.getMyloClientIdPath(), window, '');
  }

  /**
   * Determines if the useragent string is from the Facebook in-app browser
   */
  static isFacebookApp() {
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1;
  }

  /**
   * Initialize variables
   */
  initVariables() {
    // URL to redirect back to from CIAM
    const returnUrl = CiamAuth.getReturnUrl(window.location);

    // Getting MinorSource value
    const minorSource = getQueryParamObj(window.location.search).minor_source;

    // To be appended to login & signup URLs if defined
    const minorSourceParam = minorSource ? `&minor_source=${minorSource}` : '';

    // Base URL for CIAM UI
    this.ciamUiUrl = CiamAuth.getCiamUiUrl();

    // URL for the account page
    this.accountUrl = `/auth/csrf?action=account&return_url=${returnUrl}`;

    // empty URL for account link
    this.emptyUrl = '#';

    // CIAM UI url for the login page
    this.loginUrl = `/auth/csrf?action=login&return_url=${returnUrl}${minorSourceParam}`;

    // CIAM UI url for the logout page
    this.logoutUrl = `/auth/csrf?action=logout&return_url=${returnUrl}`;

    // CIAM UI url for the signup page
    this.signupUrl = `/auth/csrf?action=signup&return_url=${returnUrl}${minorSourceParam}`;

    // FRE checkaccess endpoint
    this.checkAccessEndpoint = '/auth/checkaccess/';

    // FRE spotim login endpoint
    this.spotimLoginEndpoint = '/auth/spotim-login';

    // Local storage key for saving Piano state onLoginRequired
    this.pianoStorageName = '__pianoParams';

    this.pianoParams = this.pianoGetStoredParams();

    this.pianoOfferDisplayed = false;

    this.statusCookieName = 'mylo_status';

    this.voucherCodeQueryParam = getQueryParamObj(window.location.search).voucher_code;

    this.isFacebookApp = CiamAuth.isFacebookApp();

    // account nav selectors
    this.accountButtonSelector = '.account-button';
    this.accountButtonTextSelector = '.account-text';
    this.logoutLinkSelector = '.account-sign-out';

    // cache window.HRST data object
    this.HRSTobj = window.HRST;

    // user email
    this.myloEmail = '';

    // class to signify user is signed in
    this.accountClassName = 'account-signed-in';

    // set up logger
    this.logger = new Logger('CiamAuth');

    // turn on spotim sso if spotim sso is enabled
    if (CiamAuth.isSpotIMSSOEnabled()) {
      if (window.SPOTIM && window.SPOTIM.startSSO) {
        this.spotimStartSSO();
      } else {
        addListener(document, 'spot-im-api-ready', this.spotimStartSSO.bind(this));
      }
    }
  }

  /**
   * Generate a return URL from a URL object
   * The parsed URL will contain the domain path and query parameters
   *
   * @param {object}  URL A URL object
   * @return {string}     The parsed URL
   */
  static getReturnUrl({ origin, pathname, search }) {
    const url = new URL(pathname, origin);
    url.search = search;
    return encodeURIComponent(url.toString());
  }

  /**
   * Returns true if SpotIM commenting flag is enabled and enable commenting toggle
   * on article is turned on and both spotim sso token and id are available
   */
  static isSpotIMSSOEnabled() {
    return (
      CiamAuth.isCommentingAllowed() &&
      CiamAuth.isEnableCommenting() &&
      !!CiamAuth.getSpotIMSSOToken() &&
      !!CiamAuth.getSpotIMId()
    );
  }

  /**
   * Determine if the checkaccess endpoint needs to be called
   */
  shouldCheckAccess() {
    return getCookieByName(this.statusCookieName) !== 'unauthorized';
  }

  /**
   * Check validity of access token cookie
   */
  checkTokenAccess() {
    return fetch(this.checkAccessEndpoint, fetchInit)
      .then(this.handleErrors)
      .then(response => response.json())
      .then(data => this.handleCheckAccessSuccess(data))
      .catch(err => this.handleCheckAccessFail(err));
  }

  /**
   * Reload the page if necessary and add user object data from
   * response to window.HRST.mylo
   *
   * @param  {object}  user  Object of user data to import
   */
  setupMyloData(user) {
    if (!this.preventPopupEvent && user && user.status === 'changed') {
      windowLocationReload();
    }

    if (user && user.email) {
      this.myloEmail = user.email;
      delete user.email;
    }

    const mylo = this.HRSTobj.mylo || {};
    this.HRSTobj.mylo = { ...mylo, ...user };
    // if there are any subscriber to mylo changes, call/notify all subscribing functions
    if (window.onMyloReady?.length) {
      window.onMyloReady.forEach(fn => fn && fn.constructor === Function && fn(user));
    }
  }

  /**
   * Adds listeners to interaction events with the Spot.IM interface.
   */
  spotimAddHandlers() {
    this.preventPopupEvent = false;
    addListener(document, spotimEvents, this.spotimOpenMylo.bind(this));
    addListener(window, 'mylo-auth-complete', this.myloAuthComplete.bind(this), { once: true });
    addListener(window, 'mylo-user-updated', this.myloUserUpdated.bind(this), { once: true });
  }

  /**
   * Removes the event listeners for spotim events.
   */
  spotimRemoveHandlers() {
    spotimEvents.forEach((evt) => {
      document.removeEventListener(evt, this.spotimOpenMylo);
    });
  }

  /**
   * This function is called by mylo-sdk when login is completed
   * @param {*} event object returned by the mylo-sdk when it dispatches the event
   */
  async myloAuthComplete() {
    await this.checkTokenAccess();
    this.myloStateChanged();
  }

  /**
   * Event handler for `mylo-user-updated`. Will add attribute data to `this.HRSTobj.mylo`
   *
   * @param {object} event  A CustomEvent dispatched by the Mylo sdk.
   */
  myloUserUpdated(event) {
    const attributes = getValue('data.attributes', event.detail, {});
    const mylo = this.HRSTobj.mylo || {};

    this.HRSTobj.mylo = { ...mylo, ...attributes };

    this.myloStateChanged();
  }

  /**
   * Run when mylo data is changed. Will check if spotim commenting is enabled
   * and if the mylo user data will allow it (preferredUsername has been set on the account)
   * Starts the spotim sso and removes handlers for the mylo popup window.
   */
  myloStateChanged() {
    if (CiamAuth.isSpotIMSSOEnabled() && getValue('HRSTobj.mylo.preferredUsername', this)) {
      this.spotimStartSSO();
      this.spotimRemoveHandlers();
      this.preventPopupEvent = true;
    }
  }

  /**
   * Opens the Mylo popup needed for interaction with spot.im:
   * - opens login for users who are not logged in
   * - opens username for users who are logged in but have no preferred username
   */
  spotimOpenMylo() {
    const { username, preferredUsername } = getValue('HRSTobj.mylo', this, {});
    const urlParams = {
      app_id: 'spotim',
    };
    const usernameUrl = this.mylo.getPopupUrl('username', { urlParams });

    if (this.isFacebookApp) {
      if (!username) {
        // Facebook in-app browser - redirect to signup page if user is not logged in
        windowLocationAssign(this.signupUrl);
      } else if (!preferredUsername) {
        // Facebook in-app browser - redirect to username page if user does not
        // have a preferredUsername
        windowLocationAssign(usernameUrl);
      }
    } else if (!this.preventPopupEvent) {
      if (!username) {
        // open the signup popup if user is not logged in
        this.mylo.viewPopup('signup', { urlParams });
      } else if (!preferredUsername) {
        // open the username popup if user does not have a preferredUsername
        this.mylo.viewPopup('username', { urlParams });
      }
    }
  }

  /**
   * Update the nav button text
   * Will change link to accountUrl for old nav and emptyUrl for new nav
   */
  updateNavButton(isLoggedIn) {
    this.accountButton = document.querySelector(this.accountButtonSelector);
    this.logoutLink = document.querySelector(this.logoutLinkSelector);

    if (this.accountButton) {
      // make the email variable empty
      this.myloEmail = '';

      if (isLoggedIn) {
        this.accountButtonText = this.accountButton.querySelector(this.accountButtonTextSelector);
        this.accountButtonText.firstChild.data = this.accountButtonText.dataset.account;
        this.accountButton.href = CiamAuth.isNewNav() ? this.emptyUrl : this.accountUrl;
        this.accountButton.parentElement.classList.add(this.accountClassName);
        if (this.logoutLink) {
          this.logoutLink.href = this.logoutUrl;
        }
      } else {
        this.accountButton.href = this.loginUrl;
      }
    }
  }

  /**
   * Add Piano event handlers
   */
  pianoAddHandlers() {
    window.tp = window.tp || [];
    document.addEventListener('userRefInit', this.pianoRedeemGift.bind(this));
    window.tp.push(['addHandler', 'loginRequired', this.pianoLoginRequired.bind(this)]);
    window.tp.push(['addHandler', 'showOffer', this.pianoContinueOffer.bind(this)]);
  }

  /**
   * If a user is returning to the page from CIAM after initating a Piano offer
   * continue the offer flow
   */
  pianoContinueOffer() {
    if (!this.pianoOfferDisplayed && this.pianoParams && window.tp.user.isUserValid()) {
      window.tp.offer.startCheckout(this.pianoParams);
    }
    this.pianoOfferDisplayed = true;
  }

  /**
   * Display the gift redemption modal
   * Fires after the Piano userRef token is initialized
   */
  pianoRedeemGift() {
    // if a voucher code is saved in the piano cookie continue the piano offer flow
    if (this.pianoParams && this.pianoParams.voucherCode) {
      this.pianoContinueOffer();
    } else if (this.voucherCodeQueryParam && window.tp.user.isUserValid()) {
      // reload redemption modal if voucher_code is in the query params and the user is logged in
      window.tp.offer.startRedeemVoucher({
        voucherCode: this.voucherCodeQueryParam,
      });
    }
  }

  /**
   * Get the Piano params stored by pianoLoginRequired
   *
   * @return {object|false} The parsed value of the cookie, false if the cookie is not set
   */
  pianoGetStoredParams() {
    const params = localStorage.getItem(this.pianoStorageName);
    if (params) {
      localStorage.removeItem(this.pianoStorageName);
    }
    return JSON.parse(params);
  }

  /**
   * Handle Piano events that require login
   *
   * - Saves offer parameters in a local storage to restore state after user has logged in
   * - Redirects to the CIAM signup URL
   *
   * @param {object} params The Piano offer parameters
   */
  pianoLoginRequired(params = {}) {
    if (!window.tp.user.isUserValid()) {
      localStorage.setItem(this.pianoStorageName, JSON.stringify(params));

      const url = params.startScreen === 'login' ? this.loginUrl : this.signupUrl;
      windowLocationAssign(url);
    }
  }

  /**
   * Log a failed access token check ajax request
   *
   * @param {object} xhr jQuery xhr
   * @param {string} status Text status of the error
   * @param {object} err Error object
   */
  handleCheckAccessFail(error) {
    this.logger.warn('Check Access Failure: ', error);
  }

  /**
   * If valid subscription, hide account button
   *
   * @param {object} response object
   */
  checkSubscription(user) {
    this.subscribeButton = document.querySelector('.top-nav-subscribe');
    const subscriptions = user.subscriptions;
    if (
      subscriptions &&
      subscriptions.filter(sub => sub.name === 'activeSubscriber').length > 0
    ) {
      this.subscribeButton.style.visibility = 'hidden';
    }
  }

  /**
   * If valid/refreshed cookie, update nav button
   * and add user object data to window.HRST
   *
   * @param {object} response object
   */
  handleCheckAccessSuccess({ user }) {
    this.updateNavButton(!!user);
    if (user) {
      this.checkSubscription(user);
      this.setupMyloData(user);
    }
  }

  /**
   * Catch network error for fetch
   *
   * @return {object} response object
   */
  handleErrors(response) {
    const { ok } = response;
    if (!ok) {
      this.logger.warn('Fetch Error: ', response);
      return false;
    }
    return response;
  }
}
