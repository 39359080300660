import Logger from 'app/shared/logger';
import GenericModelView from 'app/modelviews/genericmodelview';
import SearchOverlayCollection from 'app/collections/searchoverlaycollection';
import { getValue } from 'app/shared/utils';

export default Backbone.View.extend({
  el: '.search-overlay',

  events: {
    'keydown .search-input': 'inputKeyDownHandler',
  },

  initialize: function initialize() {
    // set up core variables
    this.setVariables();
    // set up event listeners
    this.setListeners();
  },

  // begin getters/setters
  setVariables: function setVariables() {
    // set up properties, overriding provided defaults with values from this.options if found
    this.overrideDefaults({
      // searchAutosuggest underscore template selector
      autosuggestLinkTemplateSelector: '#autosuggest-link-template',
      // maximum size for autosuggest results
      resultSize: 10,
      // class applied to highlighted elements
      highlightClass: 'highlight',
      // class applied to active elements
      activeClass: 'active',
    });

    // search close button
    this.$searchCloseButton = this.$('.search-overlay-close-button');

    // search input field
    this.$searchInputField = this.$('.search-input');

    // search input value
    this.searchInputVal = null;

    // autosuggest container
    this.$autosuggestContainer = this.$('.search-overlay-autosuggest-list');

    // set model to be used when rendering items
    this.itemModel = GenericModelView.prototype.ModelTemplate;

    // set view to be used when rendering items
    this.itemView = GenericModelView;

    // set collection
    this.collection = new SearchOverlayCollection([], { model: this.itemModel });

    // set up the logger
    this.logger = new Logger('SearchOverlay');

    // get the initial state of autosuggest
    this.state = this.getInitialState();
  },

  setListeners: function setListeners() {
    this.collection.on('sync', this.collectionSync.bind(this));
    // listen to search input field change event
    this.$searchInputField.on('change input', _.debounce(this.inputChange.bind(this), 100));

    // listen to the sync event for the SearchOverlayCollection
    this.collection.on('sync', this.collectionSync.bind(this));
  },

  getInitialState: function getInitialState() {
    return {
      // jQuery object representing children of autosuggest container,
      $listChildren: [],

      // current position index in autosuggest container's list that is being highlighted
      currentPosition: -1,

      // autosuggest results array.
      suggestions: null,
    };
  },
  // end getters/setters

  // begin event handlers
  /**
   * Handle inputChange event
   */
  inputChange: function inputChange() {
    this.searchInputVal = this.$searchInputField.val();

    // if input field loses focus, no need to do anything, just return
    if (!this.$searchInputField.is(':focus')) {
      return false;
    }

    // if input field is empty, clear autosuggest container, reset state and return
    if (!this.searchInputVal) {
      this.$autosuggestContainer.html('');

      this.state = this.getInitialState();

      return false;
    }

    // fetch remote data
    return this.collection
      .fetch({
        data: {
          input: this.searchInputVal,
        },
      })
      .fail(this.handleDataError.bind(this));
  },

  /**
   * Handle keydown event for input field
   */
  inputKeyDownHandler: function inputKeyDownHandler(e) {
    let code = e.keyCode || e.which;

    this.logger.log('input key: ' + code);

    switch (code) {
      case 40: // down
        this.moveToPosition(this.getNextPostion('next'));
        break;
      case 38: // up
        this.moveToPosition(this.getNextPostion('prev'));
        break;
      case 39: // right
        this.moveToPosition(0);
        break;
      default:
        break;
    }
  },
  // end event handlers

  // begin collection functions
  /**
   * move highlight from current index to new position
   */
  moveToPosition: function moveToPosition(position) {
    if (position === -1 || position > this.state.$listChildren.length - 1) {
      return false;
    }

    // remove all possible highlights
    this.state.$listChildren.removeClass(this.highlightClass);

    // highlight new position
    this.state.$listChildren.eq(position).addClass(this.highlightClass);

    // set input field value to highlighted suggestion
    this.$searchInputField.val(
      getValue('attributes.data.label', this.state.suggestions[position], ''),
    );

    // set current position
    this.state.currentPosition = position;

    return this.state.currentPosition;
  },

  /**
   * move to next position in the autosuggest list,
   * based on moving direction ( "next" or "prev" ) and current position.
   * will loop if current position is at the ends of the list.
   */
  getNextPostion: function getNextPostion(direction) {
    let length = this.state.$listChildren.length;
    let value;

    // if autosuggest list is empty, reset next postion
    if (length === 0) {
      return -1;
    }

    // if current position is not set, set next position to the first
    if (this.state.currentPosition === -1) {
      return 0;
    }

    if (direction === 'next') {
      value = this.state.currentPosition + 1;
      return value % length;
    }

    if (direction === 'prev') {
      value = this.state.currentPosition - 1;
      value += length;
      return value % length;
    }

    // should not come here. reset next position
    return -1;
  },

  /**
   * collectionSync handles the collection sync event, creating search tag
   * views for each fetched model and appending them to the DOM in batch.
   *
   * @param {object} collection Reference to the affected Backbone collection
   * @param {array}  models     Array of fetched model attributes.
   */
  collectionSync: function collectionSync(collection, models) {
    let tags = '';
    let ItemView = this.itemView;

    // if the response's search term is different from current search term,
    // the response is obsolete, no need to process
    if (models.length && this.searchInputVal !== models[0].input) {
      return false;
    }

    // keep the most relevant results
    models = collection.models.slice(0, this.resultSize);

    // loop through the models and create views
    models.forEach(
      function forEach(model) {
        let tagView = new ItemView({
          model,
          templateSelector: this.autosuggestLinkTemplateSelector,
        });

        tags += tagView.render().el;
      }.bind(this),
    );

    // update DOM with tags view
    this.$autosuggestContainer.html(tags);

    // update state with latest sync results
    this.state = {
      $listChildren: this.$autosuggestContainer.children('li'),
      currentPosition: -1,
      suggestions: models,
    };

    return this.state;
  },

  /**
   * handleDataError handles an error response
   */
  handleDataError: function handleDataError(xhr, status, err) {
    // throw a warning
    this.logger.warn('Error auto loading.', xhr, status, err);
  },
  // end collection functions
});
