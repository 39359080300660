/**
 * GlobalScript defines a set of functionality that all pages should be using.
 * Modules that span every page should be instantiated here.
 */
import CiamAuth from 'app/modules/ciamauth';
import ClickToPlay from 'app/modules/clicktoplay';
import loader from 'app/modules/lazysizesloader';
import MediaLoader from 'app/modules/medialoader';
import dayjs from 'dayjs';
import GifVideoLoader from 'app/modules/gifvideoloader';
import JamRenderer from 'app/modules/jamrenderer';
import MobileAdhesionUnit from 'app/modules/mobileadhesionunit';
import NavMenu from 'app/modules/navmenu';
import PaywallBanner from 'app/modules/paywallbanner';
import SidePanel from 'app/modules/sidepanel';
import SearchOverlay from 'app/modules/searchoverlay';
import HashedModals from 'app/modules/hashedModals';
import Share from 'app/modules/share';
import { closeModals, activateFocusTrap } from 'app/modules/focustrap';
import Xcust from 'app/modules/xcust';
import 'picturefill';
import Sitemap from 'app/modules/sitemap';

export default Backbone.View.extend({
  el: document, // Bind to global document

  initialize: function initialize() {
    this.setVariables();
    this.setListeners();
    this.modalsToClose = [
      { type: 'hashes', values: ['#sidepanel', '#searchoverlay', '#location-choice'] },
      { type: 'element', value: '.show-account-dropdown' },
    ];
    closeModals(this.modalsToClose);

    // localize date elements
    _.each(this.$('.js-date'), this.localizeDate);

    GifVideoLoader();

    this.xcust = new Xcust();
  },

  setVariables: function setVariables() {
    this.overrideDefaults({
      noScrollClass: 'no-scroll',
      templateType: false,
    });

    this.navMenu = NavMenu.setup();

    this.paywallBanner = PaywallBanner.setup();

    this.sidePanel = new SidePanel();

    this.searchOverlay = new SearchOverlay();

    // set up social and analytics tracking
    this.share = new Share();

    // set up the lazy loader
    this.mediaLoader = new MediaLoader();

    this.Sitemap = new Sitemap();

    loader.initLazysizes(this.templateType);

    this.clickToPlay = new ClickToPlay({ mediaLoader: this.mediaLoader });

    if (document.querySelector('.sponsor-bar')) {
      Promise.resolve(import('app/modules/sponsorbar')).then(({ default: SponsorBar }) => {
        SponsorBar();
      });
    }

    this.ciamauth = CiamAuth.setup();

    /**
     * Initialize JAM (Journey Access Management) renderer
     */
    JamRenderer.init();

    MobileAdhesionUnit.setup();

    // cached here so the event can be removed
    this.tabListener = this.listenForTabKey.bind(this);
  },

  setListeners: function setListeners() {
    // Extension to manage hashedModals state (should be set before FocusTrap)
    this.hashedModals = new HashedModals();

    const hashesOnWatch = ['#searchoverlay', '#sidepanel', '#location-choice'];
    $(window).on('hashchange', () => activateFocusTrap(hashesOnWatch));
    $(window).on('load', () => activateFocusTrap(hashesOnWatch));
    // set up the no-scroll listener for both touchmove and pointermove events
    $('.touchevents body, .pointerevents body').on(
      'touchmove pointermove',
      this.mobileNoScrollListener.bind(this),
    );
    // Listen to tab events to enable outlines (accessibility improvement)
    document.body.addEventListener('keyup', this.tabListener);
  },

  /**
   * Attempts to patch iOS and Android limited support for using
   * overflow:hidden on the body to prevent scrolling.
   *
   * NOTE: Adding position:fixed works, but jumps the page to
   * the top. While clunkier, this method doesn't jump.
   *
   * @param {Object} evt Touchmove Event
   */
  mobileNoScrollListener: function mobileNoScrollListener(evt) {
    // check to see if the delegate target has the no-scroll class
    if ($(evt.delegateTarget).hasClass(this.noScrollClass)) {
      // prevent scrolling
      evt.preventDefault();
    }
  },

  /**
   * Localize the article publish date.
   *
   * @param {Element} el DOM element containing a date as it's text.
   */
  localizeDate: function localizeDate(el) {
    let $el = $(el);
    let utcDate = $el.data('publish-date');
    let dateFormat = $el.data('format');

    // if no UTC publish date is provided, don't do anything
    if (!utcDate) {
      return;
    }

    // Use the existing text and moment.js to update to local time
    $el.text(dayjs(utcDate).format(dateFormat));
  },

  /**
   * Remove body class when tab key is pressed (used for detecting keyboard users)
   */
  listenForTabKey: function listenForTabKey(e) {
    let event = e || window.event;
    if (event.keyCode === 9) {
      /* tab button */
      document.body.classList.remove('no-tab');
      document.body.removeEventListener('keyup', this.tabListener);
    }
  },
});
