import { noop as observe } from 'app/shared/utils';

// load polyfill if no IntersectionObserver found in window
/* istanbul ignore if */
if (!('IntersectionObserver' in window)) {
  import('intersection-observer');
}

/**
 * observer handler callback for IntersectionObserver
 * @return {function} observer callback function
 */
const inviewHandler = (handlers) => {
  const [inView, outOfView] = handlers;
  return (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        inView(entry, observer);
      } else {
        outOfView(entry, observer);
      }
    });
  };
};

/**
 * Function to create IntersectionObserver
 * @param {function} handlers     callback functions to handle inview and outofview behavior
 * @param {object} options        options used to customize observer
 * @return {object} Returns a new IntersectionObserver object
 */
const create = (handlers, options) => {
  if ('IntersectionObserver' in window) {
    return new IntersectionObserver(inviewHandler(handlers), options);
  }
  return { observe };
};

export { create, inviewHandler };
